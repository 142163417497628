import { Card, CardBody } from "@nextui-org/react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { useTheme } from "src/components/ThemeSwitcher";
import { IProject } from "src/types";
import getCountryBbox from "src/utils/getCountryBbox";
import FloatingFilter from "./FloatingFilter";
import ProjectContainer from "./ProjectContainer";
import ProjectControlBar from "./ProjectControlBar";
import ProjectDrawer from "./ProjectDrawer";
import { useFilter } from "./useFilter";
import FilterDrawer from "./FilterDrawer";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia2FnYW5hbGlzYXJpIiwiYSI6ImNsa2E4djNjcjA0ZGwzcnFvMmw2Nmhtb3IifQ.p6lr-QvYn3E6BLfOkkJ9Sw";

/**
 * TODO bbox: European Union
 * TODO bbox: Carbon Offsetting and Reduction Scheme for International Aviation
 */
export default function ProjectMap({ id }: { id?: number }) {
  const theme = useTheme();
  const projects = useLoaderData() as IProject[];
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [viewType, setViewType] = useState<"list" | "grid">("grid");
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);

  const filterHook = useFilter({ projects });
  const [, , setFilterVisibility] = filterHook.toggle;
  const [hoveredProject, setHoveredProject] = useState<IProject | null>(null);
  const [countryCodes, setCountryCodes] = useState<string[]>([]);
  const [projectsExpanded, setProjectsExpanded] = useState(false);

  useEffect(() => {
    console.log(
      `================ ProjectMap mounted with ${filterHook.filteredProjects.length} projects ================`,
      filterHook.filteredProjects
    );

    const map = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style:
        theme === "light"
          ? "mapbox://styles/mapbox/light-v11"
          : "mapbox://styles/mapbox/dark-v11",
      center: [-70, 30],
      zoom: 3,
      attributionControl: false,
    });
    mapRef.current = map;

    map.on("load", () => {
      map.addSource("country-boundaries", {
        type: "vector",
        url: "mapbox://mapbox.country-boundaries-v1",
      });

      const addedCountryCodes: string[] = [];

      for (const project of filterHook.filteredProjects) {
        const countryCode = project.country_code;
        console.log(
          `Project ${project.id} ${project.country} (Country code: ${countryCode})`
        );
        if (countryCode) {
          if (!addedCountryCodes.includes(countryCode)) {
            // console.log("Adding new layer for", countryCode);
            map.addLayer({
              id: `${countryCode}-highlight`,
              type: "fill",
              source: "country-boundaries",
              "source-layer": "country_boundaries",
              filter: ["==", ["get", "iso_3166_1"], countryCode],
              paint: {
                "fill-color": "#007373",
                "fill-opacity": 0.2,
              },
            });

            map.setPaintProperty(
              `${countryCode}-highlight`,
              "fill-opacity-transition",
              {
                duration: 500,
              }
            );
            map.on("mouseleave", `${countryCode}-highlight`, () => {
              setHoveredProject(null);
              map?.setPaintProperty(
                `${countryCode}-highlight`,
                "fill-opacity",
                0.2
              );
              map.getCanvas().style.cursor = "";
            });
            map.on("mouseenter", `${countryCode}-highlight`, () => {
              setTimeout(() => {
                setHoveredProject(project);
                map?.setPaintProperty(
                  `${countryCode}-highlight`,
                  "fill-opacity",
                  0.5
                );
                map.getCanvas().style.cursor = "pointer";
              });
            });
            map.on("click", `${countryCode}-highlight`, () => {
              setFilterVisibility(false);
              setSelectedProject(project);
              zoomToProjectCountry(project);
              console.log("Clicked on", project);
            });

            addedCountryCodes.push(countryCode);
          }
        } else {
          console.warn(
            `Cannot create layert because country code not found for ${project.country_code}`
          );
        }
      }

      setCountryCodes(addedCountryCodes);
    });

    return () => {
      console.log("ProjectMap unmounted");
      map.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, filterHook.filteredProjects, setFilterVisibility, theme]);

  const zoomToProjectCountry = (
    project: IProject,
    options?: { highlight?: boolean }
  ) => {
    const countryCode = project.country_code;
    if (!countryCode) {
      return console.log(`countryCode not found for "${project.country_code}"`);
    }

    const bbox = getCountryBbox(countryCode);
    if (bbox) {
      mapRef.current?.fitBounds(bbox, {
        padding: {
          top: 0,
          bottom: window.innerHeight * (projectsExpanded ? 0.7 : 0.35) + 50,
          left: 20,
          right: 20,
        },
      });
      if (options?.highlight) {
        mapRef.current?.setPaintProperty(
          `${countryCode}-highlight`,
          "fill-opacity",
          0.5
        );
      }
    } else {
      console.warn("bbox not found for", countryCode);
    }
  };

  const handleTableHover = (project: IProject | null) => {
    console.log("Hovered project", project);
    if (!mapRef.current) return console.error("Map not ready");

    if (!project) {
      for (const countryCode of countryCodes) {
        mapRef.current.setPaintProperty(
          `${countryCode}-highlight`,
          "fill-opacity",
          0.2
        );
      }
      return;
    }

    zoomToProjectCountry(project, { highlight: true });
  };

  return (
    <div className="w-full h-[calc(100dvh-64px)] relative xl mx-auto gap-3">
      <FloatingFilter filterHook={filterHook} />
      <FilterDrawer filterHook={filterHook} />
      <ProjectDrawer
        project={selectedProject}
        setSelectedProject={(r) => {
          setFilterVisibility(false);
          setSelectedProject(r);
        }}
      />
      <div ref={mapContainerRef} className="absolute w-full h-full"></div>
      <div className="absolute w-full bottom-0 flex justify-center">
        <div className="container">
          <Card className="rounded-b-none z-10">
            <CardBody className="p-0">
              <ProjectControlBar
                viewType={viewType}
                setViewType={setViewType}
                infoText={`${filterHook.filteredProjects.length} of ${projects.length} items`}
                filterHook={filterHook}
                projectsExpanded={projectsExpanded}
                setProjectsExpanded={setProjectsExpanded}
              />
              <ProjectContainer
                projects={filterHook.filteredProjects}
                hoveredProject={hoveredProject}
                viewType={viewType}
                onHover={handleTableHover}
                setSelectedProject={(project) => {
                  setSelectedProject(project);
                  if (project) {
                    zoomToProjectCountry(project);
                  }
                }}
                projectsExpanded={projectsExpanded}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
