import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    redirect,
    Route,
    useNavigate,
    useNavigation,
} from "react-router-dom";
import Login from "./pages/Login";
import { getMe, getProjects, getRegulations } from "./utils/api";
import { Spinner } from "@nextui-org/react";
import { useEffect } from "react";
import RegulationMap from "./pages/RegulationMap";
import MultiPurposeMapLayout from "./components/MultiPurposeMapLayout";
import Luzmo from "./pages/Luzmo";
import ProjectMap from "./pages/ProjectMap";
import ErrorTest from "./pages/ErrorTest";
import useMe from "./utils/useMe";
import Voyages from "./pages/Voyages";
import Voyage from "./pages/Voyage/Voyage";
import VoyageFileDetails from "./pages/VoyageFileDetails";
import MarineLayout from "./components/MarineLayout";
import VoyageDemurrageInfo from "./pages/VoyageDemurrageInfo";
import VoyageLuzmo from "./pages/VoyageLuzmo";

// eslint-disable-next-line react-refresh/only-export-components
function Root() {
    const navigation = useNavigation();
    return (
        <>
            {navigation.state === "loading" ? (
                <div className="h-full w-full bg-gradient-to-br from-rose-400 via-fuchsia-500 to-indigo-500">
                    <div className="flex h-full w-full items-center justify-center bg-gray-200/75 dark:bg-gray-900/75">
                        <Spinner
                            size="lg"
                            color="secondary"
                            labelColor="secondary"
                        />
                    </div>
                </div>
            ) : (
                <Outlet />
            )}
        </>
    );
}
const redirectToLoginIfNoUser = async () => {
    try {
        const res = await getMe();
        return res;
    } catch {
        // console.error("Error fetching user, redirecting to login", error);
        throw redirect("/login");
    }
};

const redirectToHomeIfUser = async () => {
    try {
        await getMe();
    } catch {
        // console.log("No user as expected", err);
        return null;
    }
    throw redirect("/");
};

// eslint-disable-next-line react-refresh/only-export-components
const Home = () => {
    const navigate = useNavigate();
    const { me } = useMe();

    useEffect(() => {
        if (me?.role === "GOLD_STANDARD") {
            navigate("/map");
        } else if (me?.role === "CHARTERER") {
            navigate("/voyages");
        } else if (me?.role === "ADMIN") {
            navigate("/map");
        }
    }, [me, navigate]);
    return null;
};

// eslint-disable-next-line react-refresh/only-export-components
const MultiPurposeMap = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/map/1");
    }, [navigate]);
    return null;
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Root />}>
            <Route
                path="/login"
                element={<Login />}
                loader={redirectToHomeIfUser}
            />
            <Route loader={redirectToLoginIfNoUser}>
                <Route path="/" element={<Home />} loader={getRegulations} />
                <Route
                    element={<MultiPurposeMapLayout />}
                    loader={redirectToLoginIfNoUser}
                >
                    <Route path="/map" element={<MultiPurposeMap />} />
                    <Route
                        path="/map/1"
                        element={<RegulationMap id={1} />}
                        loader={getRegulations}
                    />
                    <Route
                        path="/map/2"
                        element={<ProjectMap id={2} />}
                        loader={getProjects}
                    />
                    <Route path="/proposal" element={<Luzmo panoId={202} />} />
                </Route>
                <Route path="/" element={<Home />} loader={getRegulations} />
                <Route element={<MarineLayout />}>
                    <Route path="/voyages" element={<Voyages />} />
                    <Route path="/voyages/:id" element={<Voyage />} />
                    <Route
                        path="/voyages/:id/files/:fileId"
                        element={<VoyageFileDetails />}
                    />
                    <Route
                        path="/voyages/:id/demurrage-form"
                        element={<VoyageDemurrageInfo />}
                    />
                    <Route
                        path="/voyages/:id/demurrage-calculator"
                        element={<VoyageLuzmo />}
                    />
                    <Route path="/error-test" element={<ErrorTest />} />
                </Route>
            </Route>
        </Route>
    )
);

export default router;
