import {
  Card,
  CardBody
} from "@nextui-org/react";
import { useEffect } from "react";

export default function ErrorTest() {
  useEffect(() => {
    throw new Error("Error test")
  }, [])

  return (
    <div className="flex h-full overflow-y-scroll w-screen items-center justify-center bg-gradient-to-br from-rose-400 via-fuchsia-500 to-indigo-500 p-2 sm:p-4 lg:p-8">
      <Card
        isBlurred
        className="border-none bg-background/60 dark:bg-default-100/70 max-w-[520px] w-[420px] min-w-[320px] h-32"
        shadow="sm"
      >
        <CardBody className="flex justify-center items-center px-4 sm:px-8 pt-6 pb-10 text-center">
          Error Test
        </CardBody>
      </Card>
    </div>
  );
}
