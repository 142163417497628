import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { IProject } from "src/types";
import { useToggle } from "usehooks-ts";

export const countryOptions = [
  { key: "IN", label: "India", continent: "Asia" },
  { key: "KE", label: "Kenya", continent: "Africa" },
  { key: "BW", label: "Botswana", continent: "Africa" },
  { key: "MW", label: "Malawi", continent: "Africa" },
  { key: "GH", label: "Ghana", continent: "Africa" },
  { key: "NG", label: "Nigeria", continent: "Africa" },
  { key: "TL", label: "Timor-Leste", continent: "Asia" },
  { key: "CN", label: "China", continent: "Asia" },
  { key: "ZW", label: "Zimbabwe", continent: "Africa" },
  { key: "DO", label: "Dominican Republic", continent: "North America" },
  { key: "ER", label: "Eritrea", continent: "Africa" },
  { key: "HT", label: "Haiti", continent: "North America" },
  { key: "ET", label: "Ethiopia", continent: "Africa" },
  { key: "CD", label: "Congo", continent: "Africa" },
];

const filterprojects = (projects: IProject[], filter: Filter): IProject[] => {
  return projects.filter((project) => {
    if (filter.query) {
      const text = `${project.sustaincert_id} ${project.name} ${project.status}`;
      if (!text.toLowerCase().includes(filter.query.toLowerCase())) {
        return false;
      }
    }

    if (filter.countries && filter.countries.size > 0) {
      if (!filter.countries.has(project.country_code || "")) {
        return false;
      }
    }

    if (filter.statuses && filter.statuses.size > 0) {
      const applies = Array.from(filter.statuses).some((status) => {
        return project.status.startsWith(status);
      });
      if (!applies) {
        return false;
      }
    }

    return true;
  });
};

export interface Filter {
  query: string;
  countries?: Set<string>;
  continent?: Set<string>;
  statuses?: Set<string>;
}

const defaultFilter: Filter = {
  query: "",
  countries: new Set([]),
  continent: new Set([]),
  statuses: new Set([]),
};

export interface FilterHook {
  filter: Filter;
  setFilter: (filter: Filter) => void;
  clear: () => void;
  update: (filterPart: Partial<Filter>) => void;
  filteredProjects: IProject[];
  toggle: [boolean, () => void, Dispatch<SetStateAction<boolean>>];
}

export const useFilter = ({
  projects,
}: {
  projects: IProject[];
}): FilterHook => {
  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const toggle = useToggle();

  const clear = () => setFilter({ ...defaultFilter });
  const update = (filterPart: Partial<Filter>) =>
    setFilter({ ...filter, ...filterPart });

  const filteredProjects = useMemo(() => {
    const filteredProjects = filterprojects(projects, filter);
    return filteredProjects;
  }, [projects, filter]);

  return {
    filter,
    setFilter,
    clear,
    update,
    filteredProjects,
    toggle,
  };
};
