import { Button } from "@nextui-org/react";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type Theme = "dark" | "light";

const ThemeSwitcherContext = createContext({
  theme: "dark",
  toggleTheme: () => {},
});
export const ThemeSwitcherProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const initialTheme = (localStorage.getItem("theme") as Theme) || "dark";
  const [theme, setTheme] = useState<Theme>(initialTheme);

  useEffect(() => {
    document.body.classList.remove("light", "dark");
    document.body.classList.add(theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeSwitcherContext.Provider value={{ theme, toggleTheme }} {...props} />
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export function useTheme() {
  return useContext(ThemeSwitcherContext)?.theme;
}

function ThemeSwitcher() {
  const { toggleTheme } = useContext(ThemeSwitcherContext);

  return (
    <Button variant="flat" isIconOnly onClick={toggleTheme}>
      <span className="iconify mdi--theme-light-dark w-6 h-6 rounded-full"></span>
    </Button>
  );
}

export default ThemeSwitcher;
