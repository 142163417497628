import { Button } from "@nextui-org/react";
import { FilterHook } from "./useFilter";

export default function FloatingFilter({
  filterHook,
}: {
  filterHook: FilterHook;
}) {
  const filteredStatuses = Array.from(filterHook.filter.statuses || []);
  return (
    <div className="absolute w-full top-0 flex justify-center">
      <div className="container px-6">
        <div className="absolute text-white z-10 top-2">
          <div className="container mx-auto flex gap-2">
            <Button
              className={
                filteredStatuses.includes("In force") ? "bg-teal-400/20" : ""
              }
              size="sm"
              color="success"
              radius="full"
              variant={
                filteredStatuses.includes("In force") ? "bordered" : "flat"
              }
              onClick={() => {
                filterHook.update({
                  statuses: new Set(["In force"]),
                });
              }}
            >
              In force
            </Button>
            <Button
              size="sm"
              color="warning"
              radius="full"
              variant={
                filteredStatuses.includes("Proposed") ? "bordered" : "flat"
              }
              onClick={() => {
                filterHook.update({
                  statuses: new Set(["Proposed"]),
                });
              }}
            >
              Proposed
            </Button>
            <Button
              size="sm"
              color="danger"
              radius="full"
              variant={
                filteredStatuses.includes("Not applicable")
                  ? "bordered"
                  : "flat"
              }
              onClick={() => {
                filterHook.update({
                  statuses: new Set(["Not applicable"]),
                });
              }}
            >
              Not applicable
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
