import { Button } from "@nextui-org/react";
import { FilterHook } from "./useFilter";

export default function FloatingFilter({
  filterHook,
}: {
  filterHook: FilterHook;
}) {
  const filteredStatuses = Array.from(filterHook.filter.statuses || []);
  return (
    <div className="absolute w-full top-0 flex justify-center">
      <div className="container px-6">
        <div className="absolute text-white z-10 top-2">
          <div className="container mx-auto flex gap-2">
            <Button
              size="sm"
              color="success"
              radius="full"
              variant={
                filteredStatuses.includes("LISTED") ? "bordered" : "flat"
              }
              onClick={() => {
                filterHook.update({
                  statuses: new Set(["LISTED"]),
                });
              }}
            >
              Listed
            </Button>
            <Button
              size="sm"
              color="warning"
              radius="full"
              variant={
                filteredStatuses.includes("DESIGN CERTIFIED") ? "bordered" : "flat"
              }
              onClick={() => {
                filterHook.update({
                  statuses: new Set(["DESIGN CERTIFIED"]),
                });
              }}
            >
              Design Certified
            </Button>
            <Button
              size="sm"
              color="warning"
              radius="full"
              variant={
                filteredStatuses.includes("PROJECT CERTIFIED")
                  ? "bordered"
                  : "flat"
              }
              onClick={() => {
                filterHook.update({
                  statuses: new Set(["PROJECT CERTIFIED"]),
                });
              }}
            >
              Project Certified
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
