import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader
} from "@nextui-org/react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Scanning, SofBlockMapping } from "src/types";
import { updateScanningBlock } from "src/utils/api";
import "./VoyageFileDetails.css";


export default function PdfTab({
    scanning,
    onRefetch,
    highlightVisibility,
}: {
    scanning: Scanning;
    onRefetch: () => void;
    highlightVisibility: "opacity-rotate" | "opacity-100" | "opacity-0";
}) {
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [numPages, setNumPages] = useState(1);
    const [editingField, setEditingField] = useState<
        keyof SofBlockMapping | undefined
    >(undefined);

    // const reversedAiBlockMapping = scanning?.ai_block_mapping
    //     ? Object.fromEntries(
    //         Object.entries(scanning.ai_block_mapping).map(([key, value]) => [
    //             value,
    //             key,
    //         ])
    //     )
    //     : {};
    const blocks = scanning?.textract_response?.Blocks?.filter(
        (b) => b.BlockType === "LINE"
    );

    return (
        <>
            <EditModal
                scanning={scanning}
                field={editingField}
                open={Boolean(editingField)}
                onClose={() => setEditingField(undefined)}
                onSuccess={() => onRefetch()}
            />
            <div className="relative min-w-[1024px]">
                {/* <div
                    className={`absolute top-0 left-0 w-full h-full overflow-auto ${highlightVisibility} light`}
                    style={{ zIndex: 3 }}
                >
                    {blocks
                        ?.filter((block) => reversedAiBlockMapping[block.Id])
                        .map((block, index) => {
                            const field = reversedAiBlockMapping[block.Id];
                            const { Left, Top } = block.Geometry.BoundingBox;

                            return (
                                <Button
                                    key={`${field}-${index}`}
                                    isIconOnly
                                    size="sm"
                                    variant="flat"
                                    radius="full"
                                    style={{
                                        position: "absolute",
                                        left: `${Left * 92}%`,
                                        top: `${Top * 100}%`,
                                        marginTop: -10,
                                        marginLeft: -26,
                                        zIndex: 3,
                                    }}
                                    onClick={() => {
                                        setEditingField(field);
                                    }}
                                >
                                    <span className="iconify mdi--edit w-4 h-4"></span>
                                </Button>
                            );
                        })}
                </div> */}
                {scanning.s3_url && (
                    <Worker
                        workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                    >
                        <Viewer
                            fileUrl={scanning.s3_url}
                            onDocumentLoad={({ doc }) => {
                                console.log("onDocumentLoad", doc.numPages);
                                setNumPages(doc?.numPages || 1);
                                setPdfLoaded(true)
                            }}

                        />
                    </Worker>
                )}

                <div
                    className={`absolute top-0 left-0 w-full h-full pointer-events-none overflow-auto ${highlightVisibility}`}
                    style={{ zIndex: 1 }}
                >
                    <svg className="w-full h-full">
                        {pdfLoaded &&
                            blocks?.map((block, index) => {
                                const { Geometry, Text, Page } = block;
                                const { BoundingBox } = Geometry;
                                const { Left, Top, Width } = BoundingBox;

                                // let text = Text;
                                // let isModified = false;
                                // const field = reversedAiBlockMapping[Id];
                                // const formFieldValue =
                                //     scanning.ai_block_mapping_form?.[
                                //     field as keyof SofBlockMapping
                                //     ];
                                // if (field && formFieldValue) {
                                //     isModified = formFieldValue !== text;
                                //     text = formFieldValue;
                                // }

                                const xPercent = Left * 92
                                const yPercent = (Top / numPages) * 100 + (Page - 1) * (100 / numPages)
                                return (
                                    <g key={index}>
                                        <rect
                                            x={`${xPercent}%`}
                                            y={`${yPercent}%`}
                                            width={`${Width * 100}%`}
                                            height="1.2em"
                                            fill="rgba(212, 255, 212)"
                                        // fill={
                                        //     isModified
                                        //         ? "rgba(245, 242, 152)"
                                        //         : field
                                        //             ? "rgba(136, 252, 136)"
                                        //             : "rgba(212, 255, 212)"
                                        // }
                                        />
                                        <text
                                            x={`${xPercent}%`}

                                            y={`${yPercent}%`}
                                            dy="1em"
                                            fontSize="12"
                                            fill="black"
                                        >
                                            {/* {text} */}
                                            {Text}
                                        </text>
                                    </g>
                                );
                            })}
                    </svg>
                </div>
            </div>
        </>
    );
}

// ================================

const fieldLabelMap: Record<keyof SofBlockMapping, string> = {
    date: "Date",
    portName: "Port Name",
    vesselName: "Vessel Name",
};
interface EditModalProps {
    scanning: Scanning;
    field?: keyof SofBlockMapping;
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
}
const EditModal: React.FC<EditModalProps> = ({
    scanning,
    field,
    open,
    onClose,
    onSuccess,
}) => {
    const defaultValue = scanning.ai_block_mapping_form?.[field!] || "";
    const [inputValue, setInputValue] = useState(defaultValue);
    const mutation = useMutation({
        mutationFn: (value: string) =>
            updateScanningBlock(scanning.id, {
                field: field!,
                value,
            }),
        onSuccess: (data) => {
            console.log("onSuccess", data);
            onClose();
            onSuccess();
        },
    });

    useEffect(() => {
        const defaultValue = scanning.ai_block_mapping_form?.[field!] || "";
        console.log("Resetting form", defaultValue);
        setInputValue(defaultValue);
    }, [scanning, field]);

    return (
        <Modal
            closeButton
            aria-labelledby="modal-title"
            isOpen={open}
            onClose={onClose}
            isDismissable={false}
        >
            <ModalContent>
                <ModalHeader>
                    <h4 id="modal-title">{fieldLabelMap[field!]}</h4>
                </ModalHeader>
                <ModalBody>
                    <Input
                        fullWidth
                        value={inputValue}
                        onValueChange={setInputValue}
                        size="lg"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="danger"
                        variant="light"
                        onPress={onClose}
                        isDisabled={mutation.isPending}
                    >
                        Close
                    </Button>
                    <Button
                        variant="light"
                        color="secondary"
                        onPress={() => {
                            const blockId = scanning.ai_block_mapping?.[field!];
                            if (!blockId)
                                return console.error("Block ID not found");
                            const block =
                                scanning.textract_response?.Blocks?.find(
                                    (b) => b.Id === blockId
                                );
                            if (!block) return console.error("Block not found");
                            if (!block.Text)
                                return console.error("Block not found");

                            mutation.mutate(block.Text);
                        }}
                        isDisabled={mutation.isPending}
                    >
                        Reset
                    </Button>
                    <Button
                        color="secondary"
                        onPress={() => mutation.mutate(inputValue)}
                        isLoading={mutation.isPending}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
