import {
  Button,
  Chip,
  ScrollShadow,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React from "react";
import RegulationCountry from "./RegulationCountry";
import RegulationStatus from "./RegulationStatus";
import { IRegulation } from "src/types";
import formatDate from "src/utils/formatDate";
import { useEventListener } from "usehooks-ts";
import Luzmo from "../Luzmo";
import getJuristictionCode from "src/utils/getJuristictionCode";

const replaceNewLines = (text?: string | null) => {
  if (!text) {
    return null;
  }

  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const replaceNewLinesAndLinks = (text?: string | null) => {
  if (!text) {
    return null;
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line.split(urlRegex).map((part, i) =>
        urlRegex.test(part) ? (
          <a
            key={i}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            {part}
          </a>
        ) : (
          part
        )
      )}
      <br />
    </React.Fragment>
  ));
};

export default function RegulationDrawer({
  regulation,
  setSelectedRegulation,
}: {
  regulation?: IRegulation | null;
  setSelectedRegulation: (regulation: IRegulation | null) => void;
}) {
  useEventListener("keydown", (event) => {
    if (regulation && event.key === "Escape") {
      setSelectedRegulation(null);
    }
  });

  const countryCode = regulation ? getJuristictionCode(regulation) : null;
  const stakeholders = regulation?.raw_stakeholders
    ?.split(/[,\-\n]/)
    ?.map((x) => x.replace("\n", ""))
    ?.map((x) => x.trim())
    ?.filter(Boolean);

  return (
    <div
      className={`fixed z-50 bg-content1 top-0 right-0 w-full h-full overflow-y-scroll min-w-[300px] max-w-[1200px] shadow-lg transform transition-transform duration-300 ${
        regulation ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="p-4 sm:p-8 h-full flex flex-col justify-between">
        <div className="flex justify-between items-center gap-4 pb-3">
          {regulation && (
            <RegulationCountry regulation={regulation} className="w-10 h-10" />
          )}
          <p className="text-xl uppercase font-bold">
            {regulation?.raw_jurisdiction}
          </p>
          <div className="flex-1" />
          <Button
            isIconOnly
            variant="light"
            onClick={() => setSelectedRegulation(null)}
          >
            <span className="iconify mdi--close min-w-10 min-h-10"></span>
          </Button>
        </div>

        <ScrollShadow className="text-wrap overflow-x-hidden">
          {regulation && (
            <div className="flex flex-col gap-8 mt-10">
              <Table
                hideHeader
                removeWrapper
                isStriped
                className="max-w-[500px]"
                aria-label="Regulation details"
              >
                <TableHeader>
                  <TableColumn align="end">NAME</TableColumn>
                  <TableColumn>VALUE</TableColumn>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <span className="opacity-60 text-xs uppercase">
                        Status
                      </span>
                    </TableCell>
                    <TableCell>
                      <RegulationStatus regulation={regulation} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <span className="opacity-60 text-xs uppercase">
                        Type of jurisdiction
                      </span>
                    </TableCell>
                    <TableCell>
                      {regulation.raw_international_national_subnational}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <span className="opacity-60 text-xs uppercase">
                        Type of regulation
                      </span>
                    </TableCell>
                    <TableCell>{regulation.raw_type_of_regulation}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <span className="opacity-60 text-xs uppercase">
                        Enactment date
                      </span>
                    </TableCell>
                    <TableCell>
                      {regulation.raw_enactment_date
                        ? formatDate(regulation.raw_enactment_date)
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <span className="opacity-60 text-xs uppercase">
                        Entry into force date
                      </span>
                    </TableCell>
                    <TableCell>
                      {regulation.raw_entry_into_force_date
                        ? formatDate(regulation.raw_entry_into_force_date)
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <div>
                <h4 className="opacity-50 uppercase text-xs mb-2">
                  Short name of regulation/law
                </h4>
                <p className="text-lg">{regulation.raw_short_name}</p>
              </div>

              <div>
                <h4 className="opacity-50 uppercase text-xs mb-2">
                  Official name of regulation/law
                </h4>
                <p className="text-sm text-wrap">
                  {regulation.raw_official_name}
                </p>
              </div>

              <div>
                <h4 className="opacity-50 uppercase text-xs mb-2">
                  Stakeholders
                </h4>
                <div>
                  <span className="flex flex-wrap gap-2">
                    {stakeholders?.map((stakeholder, index) => (
                      <Chip
                        color="default"
                        key={index}
                        // variant="flat"
                        className="text-white/70"
                      >
                        {stakeholder}
                      </Chip>
                    ))}
                  </span>
                </div>
              </div>

              <div>
                <h4 className="opacity-50 uppercase text-xs mb-2">
                  Regulation summary
                </h4>
                <p className="text-sm text-wrap">
                  {replaceNewLines(regulation.raw_regulation_summary)}
                </p>
              </div>

              <div>
                <h4 className="opacity-50 uppercase text-xs mb-2">
                  Bilateral agreements
                </h4>
                <p className="text-sm text-wrap">
                  {replaceNewLines(regulation.raw_bilateral_agreements)}
                </p>
              </div>

              <div>
                <h4 className="opacity-50 uppercase text-xs mb-2">
                  Other notes
                </h4>
                <p className="text-sm text-wrap">
                  {replaceNewLines(regulation.raw_other_notes)}
                </p>
              </div>

              <div>
                <h4 className="opacity-50 uppercase text-xs mb-2">
                  Link to regulatory/statutory text
                </h4>
                <p className="text-sm text-wrap">
                  {replaceNewLinesAndLinks(
                    regulation.raw_link_to_regulatory_text
                  )}
                </p>
              </div>

              <div>
                <h4 className="opacity-50 uppercase text-xs mb-2">
                  Other Links
                </h4>
                <p className="text-sm text-wrap">
                  {replaceNewLinesAndLinks(regulation.raw_other_links)}
                </p>
              </div>

              <div>
                <h4 className="opacity-50 uppercase text-xs mb-4">
                  More from dataritim.libra
                </h4>
                {countryCode &&
                  countryCode !== "EU" &&
                  countryCode !== "CA" && (
                    <div className="rounded-2xl overflow-hidden">
                      <Luzmo
                        panoId={188}
                        countryCode={regulation?.raw_jurisdiction}
                      />
                    </div>
                  )}
              </div>
            </div>
          )}
        </ScrollShadow>
        <div className="flex-1" />
        <div className="flex flex-col gap-6 mt-6">
          <Button
            variant="flat"
            size="lg"
            color="secondary"
            className="h-16"
            onClick={() => setSelectedRegulation(null)}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}
