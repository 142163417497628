import { Card, CardBody } from "@nextui-org/react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { useTheme } from "src/components/ThemeSwitcher";
import { IRegulation } from "src/types";
import getCountryBbox from "src/utils/getCountryBbox";
import getJuristictionCode from "src/utils/getJuristictionCode";
import FloatingFilter from "./FloatingFilter";
import RegulationContainer from "./RegulationContainer";
import RegulationControlBar from "./RegulationControlBar";
import RegulationDrawer from "./RegulationDrawer";
import { useFilter } from "./useFilter";
import FilterDrawer from "./FilterDrawer";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia2FnYW5hbGlzYXJpIiwiYSI6ImNsa2E4djNjcjA0ZGwzcnFvMmw2Nmhtb3IifQ.p6lr-QvYn3E6BLfOkkJ9Sw";

/**
 * TODO bbox: European Union
 * TODO bbox: Carbon Offsetting and Reduction Scheme for International Aviation
 */
export default function RegulationMap({ id }: { id?: number }) {
  const theme = useTheme();
  const regulations = useLoaderData() as IRegulation[];
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [viewType, setViewType] = useState<"list" | "grid">("grid");
  const [selectedRegulation, setSelectedRegulation] =
    useState<IRegulation | null>(null);

  const filterHook = useFilter({ regulations });
  const [, , setFilterVisibility] = filterHook.toggle;
  const [hoveredRegulation, setHoveredRegulation] =
    useState<IRegulation | null>(null);
  const [countryCodes, setCountryCodes] = useState<string[]>([]);
  const [regulationsExpanded, setRegulationsExpanded] = useState(false);

  useEffect(() => {
    console.log(
      `================ RegulationMap mounted with ${filterHook.filteredRegulations.length} regulations ================`,
      filterHook.filteredRegulations
    );

    const map = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style:
        theme === "light"
          ? "mapbox://styles/mapbox/light-v11"
          : "mapbox://styles/mapbox/dark-v11",
      center: [-70, 30],
      zoom: 3,
      attributionControl: false,
    });
    mapRef.current = map;

    map.on("load", () => {
      map.addSource("country-boundaries", {
        type: "vector",
        url: "mapbox://mapbox.country-boundaries-v1",
      });

      const addedCountryCodes: string[] = [];

      for (const regulation of filterHook.filteredRegulations) {
        const countryCode = getJuristictionCode(regulation);
        // console.log(
        //   `Regulation ${regulation.id} ${regulation.raw_jurisdiction} (Country code: ${countryCode})`
        // );
        if (countryCode) {
          if (!addedCountryCodes.includes(countryCode)) {
            // console.log("Adding new layer for", countryCode);
            let fillColor = "#007373";
            if (regulation.raw_status_of_regulation === "In force") {
              fillColor = "#007373";
            } else if (
              regulation.raw_status_of_regulation.startsWith("Proposed")
            ) {
              fillColor = "rgb(251, 191, 36)"; // bg-amber-400
            } else {
              fillColor = "rgb(127, 29, 29)"; // bg-red-900
            }
            map.addLayer({
              id: `${countryCode}-highlight`,
              type: "fill",
              source: "country-boundaries",
              "source-layer": "country_boundaries",
              filter: ["==", ["get", "iso_3166_1"], countryCode],
              paint: {
                "fill-color": fillColor,
                "fill-opacity": 0.2,
              },
            });

            map.setPaintProperty(
              `${countryCode}-highlight`,
              "fill-opacity-transition",
              {
                duration: 500,
              }
            );
            map.on("mouseleave", `${countryCode}-highlight`, () => {
              setHoveredRegulation(null);
              map?.setPaintProperty(
                `${countryCode}-highlight`,
                "fill-opacity",
                0.2
              );
              map.getCanvas().style.cursor = "";
            });
            map.on("mouseenter", `${countryCode}-highlight`, () => {
              setTimeout(() => {
                setHoveredRegulation(regulation);
                map?.setPaintProperty(
                  `${countryCode}-highlight`,
                  "fill-opacity",
                  0.5
                );
                map.getCanvas().style.cursor = "pointer";
              });
            });
            map.on("click", `${countryCode}-highlight`, () => {
              setFilterVisibility(false);
              setSelectedRegulation(regulation);
              zoomToRegulationCountry(regulation);
              console.log("Clicked on", regulation);
            });

            addedCountryCodes.push(countryCode);
          }
        } else {
          console.warn(
            `Cannot create layert because country code not found for ${regulation.raw_jurisdiction}`
          );
        }
      }

      setCountryCodes(addedCountryCodes);
    });

    return () => {
      console.log("RegulationMap unmounted");
      map.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, filterHook.filteredRegulations, setFilterVisibility, theme]);

  const zoomToRegulationCountry = (
    regulation: IRegulation,
    options?: { highlight?: boolean }
  ) => {
    const countryCode = getJuristictionCode(regulation);
    if (!countryCode) {
      return console.log(
        `countryCode not found for "${regulation.raw_jurisdiction}"`
      );
    }

    const bbox = getCountryBbox(countryCode);
    if (bbox) {
      mapRef.current?.fitBounds(bbox, {
        padding: {
          top: 0,
          bottom: window.innerHeight * (regulationsExpanded ? 0.7 : 0.35) + 50,
          left: 20,
          right: 20,
        },
      });
      if (options?.highlight) {
        mapRef.current?.setPaintProperty(
          `${countryCode}-highlight`,
          "fill-opacity",
          0.5
        );
      }
    } else {
      console.warn("bbox not found for", countryCode);
    }
  };

  const handleTableHover = (regulation: IRegulation | null) => {
    // console.log("Hovered regulation", regulation);
    if (!mapRef.current) return console.error("Map not ready");

    if (!regulation) {
      for (const countryCode of countryCodes) {
        mapRef.current.setPaintProperty(
          `${countryCode}-highlight`,
          "fill-opacity",
          0.2
        );
      }
      return;
    }

    zoomToRegulationCountry(regulation, { highlight: true });
  };

  return (
    <div className="w-full h-[calc(100dvh-64px)] relative xl mx-auto gap-3">
      <FloatingFilter filterHook={filterHook} />
      <FilterDrawer filterHook={filterHook} />
      <RegulationDrawer
        regulation={selectedRegulation}
        setSelectedRegulation={(r) => {
          setFilterVisibility(false);
          setSelectedRegulation(r);
        }}
      />
      <div ref={mapContainerRef} className="absolute w-full h-full"></div>
      <div className="absolute w-full bottom-0 flex justify-center">
        <div className="container">
          <Card className="rounded-b-none z-10">
            <CardBody className="p-0">
              <RegulationControlBar
                viewType={viewType}
                setViewType={setViewType}
                infoText={`${filterHook.filteredRegulations.length} of ${regulations.length} items`}
                filterHook={filterHook}
                regulationsExpanded={regulationsExpanded}
                setRegulationsExpanded={setRegulationsExpanded}
              />
              <RegulationContainer
                regulations={filterHook.filteredRegulations}
                hoveredRegulation={hoveredRegulation}
                viewType={viewType}
                onHover={handleTableHover}
                setSelectedRegulation={(reg) => {
                  setSelectedRegulation(reg);
                  if (reg) zoomToRegulationCountry(reg);
                }}
                regulationsExpanded={regulationsExpanded}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
