import { Button } from "@nextui-org/react";
import Lottie from "lottie-react";
import underConstructionAnimation from "src/assets/lottie-under-construction.json";
import { IProject } from "src/types";
import { useEventListener } from "usehooks-ts";
import ProjectCountry from "./ProjectCountry";

export default function ProjectDrawer({
  project,
  setSelectedProject,
}: {
  project?: IProject | null;
  setSelectedProject: (regulation: IProject | null) => void;
}) {
  useEventListener("keydown", (event) => {
    if (project && event.key === "Escape") {
      setSelectedProject(null);
    }
  });

  return (
    <div
      className={`fixed z-50 bg-content1 top-0 right-0 w-full h-full overflow-y-scroll min-w-[300px] max-w-[1200px] shadow-lg transform transition-transform duration-300 ${
        project ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="p-4 sm:p-8 h-full flex flex-col justify-between">
        <div className="flex justify-between items-center gap-4 pb-3">
          {project && (
            <ProjectCountry project={project} className="w-10 h-10" />
          )}
          <p className="text-xl uppercase font-bold">{project?.country}</p>
          <div className="flex-1" />
          <Button
            isIconOnly
            variant="light"
            onClick={() => setSelectedProject(null)}
          >
            <span className="iconify mdi--close min-w-10 min-h-10"></span>
          </Button>
        </div>
        <p className="mt-4 mb-2 text-2xl opacity-80">{project?.name || ""}</p>
        <span className="opacity-50 text-nowrap">
          by {project?.project_developer || ""}
        </span>

        <div className="flex-1" />
        <Lottie
          className="mx-auto w-full max-w-[500px]"
          animationData={underConstructionAnimation}
        />
        <p className="text-3xl text-center opacity-50">Coming soon</p>

        <div className="flex-1" />
        <div className="flex flex-col gap-6 mt-6">
          <Button
            variant="flat"
            size="lg"
            color="secondary"
            className="h-16"
            onClick={() => setSelectedProject(null)}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}
