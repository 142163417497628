import {
  Avatar,
  Button,
  Chip,
  DateRangePicker,
  ScrollShadow,
  Select,
  SelectItem,
  SelectProps,
} from "@nextui-org/react";
import RegulationStatus from "src/pages/RegulationMap/RegulationStatus";
import { useEventListener } from "usehooks-ts";
import { countryOptions, FilterHook, stakeholderOptions } from "./useFilter";

const CountrySelect = (props: Partial<SelectProps>) => {
  return (
    <Select
      size="lg"
      selectionMode="multiple"
      label="Select country"
      renderValue={(items) => {
        return (
          <div className="flex gap-2">
            {items.map((item) => (
              <Chip key={item.key}>{item.textValue}</Chip>
            ))}
          </div>
        );
      }}
      {...props}
    >
      {countryOptions.map((country) => {
        return (
          <SelectItem
            key={country.key}
            className="min-h-12"
            startContent={
              <Avatar
                alt="Argentina"
                className="w-6 h-6"
                src={`https://flagcdn.com/${country.key.toLowerCase()}.svg`}
              />
            }
          >
            {country.label}
          </SelectItem>
        );
      })}
    </Select>
  );
};

const ContinentSelect = (props: Partial<SelectProps>) => {
  return (
    <Select
      size="lg"
      label="Select continent"
      disabledKeys={["Australia"]}
      {...props}
    >
      <SelectItem key="Asia" className="min-h-12">
        Asia
      </SelectItem>
      <SelectItem key="Africa" className="min-h-12">
        Africa
      </SelectItem>
      <SelectItem key="North America" className="min-h-12">
        North America
      </SelectItem>
      <SelectItem key="South America" className="min-h-12">
        South America
      </SelectItem>
      <SelectItem key="Europe" className="min-h-12">
        Europe
      </SelectItem>
      <SelectItem key="Australia " className="min-h-12" isDisabled>
        Australia
      </SelectItem>
    </Select>
  );
};

const statusOptions = [
  { key: "In force", label: "In force", color: "bg-teal-400/20" },
  { key: "Proposed", label: "Proposed", color: "warning" },
  { key: "Not applicable", label: "Not applicable", color: "danger" },
];
const StatusSelect = (props: Partial<SelectProps>) => {
  return (
    <Select
      size="lg"
      selectionMode="multiple"
      label="Select status"
      renderValue={(items) => {
        return (
          <div className="flex gap-2">
            {items.map((item) => (
              <RegulationStatus key={item.textValue} status={item.textValue} />
            ))}
          </div>
        );
      }}
      {...props}
    >
      {statusOptions.map((option) => {
        return (
          <SelectItem key={option.key} className="min-h-12">
            {option.label}
          </SelectItem>
        );
      })}
    </Select>
  );
};

const StakeholderOptions = (props: Partial<SelectProps>) => {
  return (
    <Select
      size="lg"
      selectionMode="multiple"
      className="overflow-y-hidden"
      label="Select stakeholders"
      renderValue={(items) => {
        return (
          <div className="flex gap-2">
            {items.map((item) => (
              <Chip key={item.key}>{item.textValue}</Chip>
            ))}
          </div>
        );
      }}
      {...props}
    >
      {stakeholderOptions.map((stakeholder) => {
        return (
          <SelectItem key={stakeholder} className="min-h-12">
            {stakeholder}
          </SelectItem>
        );
      })}
    </Select>
  );
};

export default function FilterDrawer({
  filterHook,
}: {
  filterHook: FilterHook;
}) {
  const [isFilterVisible, toggleFilterVisibility] = filterHook.toggle;

  useEventListener("keydown", (event) => {
    if (isFilterVisible && event.key === "Escape") {
      toggleFilterVisibility();
    }
  });

  return (
    <div
      className={`fixed z-40 bg-content1 top-0 right-0 w-full h-full overflow-y-scroll min-w-[300px] max-w-[650px] shadow-lg transform transition-transform duration-300 ${
        isFilterVisible ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="p-4 sm:p-8 h-full flex flex-col justify-between">
        <div className="flex justify-between items-center gap-4 py-4">
          <h2 className="text-lg font-semibold">Filters</h2>
          <Button
            variant="light"
            startContent={<span className="iconify mdi--delete w-4 h-4"></span>}
            onClick={() => filterHook.clear()}
          >
            Clear all
          </Button>
          <div className="flex-1" />
          <Button
            isIconOnly
            variant="light"
            onClick={() => toggleFilterVisibility()}
          >
            <span className="iconify mdi--close min-w-8 min-h-8"></span>
          </Button>
        </div>
        <ScrollShadow className="flex-1">
          <div className="flex flex-col gap-6">
            <CountrySelect
              selectedKeys={filterHook.filter.countries}
              onSelectionChange={(newCountries) => {
                filterHook.update({
                  countries: newCountries as Set<string>,
                  continent: new Set([]),
                });
              }}
            />
            <ContinentSelect
              selectedKeys={filterHook.filter.continent}
              onSelectionChange={(newContinent) => {
                const countries = countryOptions.filter((country) => {
                  return country.continent === newContinent.currentKey;
                });
                filterHook.update({
                  countries: new Set(countries.map((country) => country.key)),
                  continent: newContinent as Set<string>,
                });
              }}
            />
            <StatusSelect
              selectedKeys={filterHook.filter.statuses}
              onSelectionChange={(newStatuses) => {
                filterHook.update({
                  statuses: newStatuses as Set<string>,
                });
              }}
            />
            <DateRangePicker
              label="Entry into force"
              size="lg"
              visibleMonths={2}
              value={filterHook.filter.entryIntoForce}
              onChange={(newDateRange) => {
                filterHook.update({
                  entryIntoForce: newDateRange,
                });
              }}
            />
            <StakeholderOptions
              selectedKeys={filterHook.filter.stakeholders}
              onSelectionChange={(newStakeholders) => {
                filterHook.update({
                  stakeholders: newStakeholders as Set<string>,
                });
              }}
            />
            <Button
              variant="flat"
              size="lg"
              startContent={
                <span className="iconify mdi--delete w-6 h-6"></span>
              }
              className="min-h-16"
              onClick={() => filterHook.clear()}
            >
              Clear all
            </Button>
          </div>
        </ScrollShadow>
        {/* <div className="flex-1" /> */}
        <Button
          variant="flat"
          size="lg"
          color="secondary"
          className="h-16 mt-4"
          onClick={() => toggleFilterVisibility()}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
