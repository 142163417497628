import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Select,
    SelectItem
} from "@nextui-org/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Lottie from "lottie-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import docScanAnimation from "src/assets/lottie-doc-scan.json";
import ScanningStatus from "src/components/ScanningStatus";
import { scanPdf } from "src/utils/api";

interface ExtendedFile {
    file: File;
    fileType?: VoyageFileType;
    status?: "UPLOADING" | "UPLOADED"
}
type VoyageFileType = (typeof scanningFileTypes)[number];

export default function NewVoyageFile({ voyageId }: { voyageId: string }) {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const [fileObjs, setFileObjs] = useState<ExtendedFile[]>([]);
    const [selectedFileObj, setSelectedFileObj] = useState<ExtendedFile | null>(
        null
    );
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);

    const mutation = useMutation({
        mutationFn: (fileObj: ExtendedFile) =>
            scanPdf(fileObj.file, fileObj.fileType!, voyageId),
        onSuccess: (data) => {
            console.log("onSuccess", data);
            // console.log("presigned_url", data?.presigned_url);
            // navigate(`/voyages/${id}/files/${data.id}`);
        },
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileObjs(
            Array.from(event.target.files || [])?.map((file) => ({
                file,
                fileType: "Other",
            })) || []
        );
    };

    const handlePreviewFile = (fileObj: ExtendedFile) => {
        if (fileObj) {
            console.log("Selected file:", fileObj);
            setSelectedFileObj(fileObj);
            const url = URL.createObjectURL(fileObj.file);
            setPdfUrl(url);
        }
    };

    const handleSubmit = async () => {
        if (!fileObjs?.length) {
            return console.error("No file selected");
        }
        for (let i = 0; i < fileObjs.length; i++) {
            setFileObjs((prevFileObjs) => {
                const newFileObjs = [...prevFileObjs];
                newFileObjs[i].status = "UPLOADING";
                return newFileObjs;
            });
            await mutation.mutateAsync(fileObjs[i]);
            queryClient.invalidateQueries({ queryKey: ["scannings", id] });
            setFileObjs((prevFileObjs) => {
                const newFileObjs = [...prevFileObjs];
                newFileObjs[i].status = "UPLOADED";
                return newFileObjs;
            });
        }
        console.log("Uploading files:", fileObjs);
        // mutation.mutate(files);
    };

    const isCompleted = fileObjs.every(fileObj => fileObj.status === "UPLOADED");

    return (
        <>
            <Card className="h-full">
                <CardHeader>
                    <h3 className="text-sm uppercase opacity-60">
                        Scan New Document
                    </h3>
                </CardHeader>
                <CardBody className="gap-3">
                    <div className="flex-grow" />
                    <div className="flex gap-8 items-end flex-wrap sm:flex-nowrap">
                        <Input
                            type="file"
                            // label="Upload File"
                            size="lg"
                            multiple
                            onChange={handleFileChange}
                            isDisabled={mutation.isPending}
                        />
                    </div>
                    {fileObjs.map((fileObj, index) => {
                        return (
                            <div
                                key={index}
                                className="flex gap-8 items-center flex-wrap sm:flex-nowrap"
                            >
                                <Select
                                    label="Select File Type"
                                    placeholder="Choose a file type"
                                    className="w-64"
                                    isDisabled={mutation.isPending}
                                    onChange={(e) => {
                                        setFileObjs((prev) =>
                                            prev.map((obj, i) =>
                                                i === index
                                                    ? {
                                                        ...obj,
                                                        fileType: e.target
                                                            .value as VoyageFileType,
                                                    }
                                                    : obj
                                            )
                                        );
                                    }}
                                    selectedKeys={
                                        fileObj.fileType
                                            ? [fileObj.fileType]
                                            : []
                                    }
                                >
                                    {scanningFileTypes.map((type) => (
                                        <SelectItem key={type} value={type}>
                                            {type}
                                        </SelectItem>
                                    ))}
                                </Select>
                                <p className="flex-1">{fileObj.file.name}</p>
                                <div className="flex gap-3">
                                    <ScanningStatus status={fileObj.status!} isIconOnly />
                                    <Button
                                        isIconOnly
                                        onClick={() =>
                                            handlePreviewFile(fileObj)
                                        }
                                    >
                                        <span className="iconify mdi--eye w-6 h-6"></span>
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                    {fileObjs?.length > 0 && (
                        <Button
                            color="secondary"
                            size="lg"
                            onPress={() => handleSubmit()}
                            isLoading={mutation.isPending}
                            isDisabled={isCompleted}
                        >
                            Upload Files
                        </Button>
                    )}
                </CardBody>
            </Card>
            <Modal
                closeButton
                size="5xl"
                aria-labelledby="modal-title"
                isOpen={Boolean(selectedFileObj)}
                onClose={() => setSelectedFileObj(null)}
                isDismissable={false}
                scrollBehavior="inside"
            >
                <ModalContent>
                    <ModalHeader>
                        <h3>{selectedFileObj?.fileType}</h3>
                    </ModalHeader>
                    <ModalBody>
                        {selectedFileObj?.file?.type === "application/pdf" ? (
                            <>
                                {pdfUrl ? (
                                    <div className="relative h-[75vh]">
                                        {mutation.isPending && (
                                            <div className="absolute left-0 top-0 right-0 bottom-0 flex flex-col items-stretch">
                                                <div className="bg-white h-full flex-1 w-full" />
                                                <Lottie
                                                    animationData={
                                                        docScanAnimation
                                                    }
                                                    className="-my-20"
                                                />
                                                <div className="bg-white h-full flex-1 w-full" />
                                            </div>
                                        )}
                                        <embed
                                            src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                                            type="application/pdf"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                ) : (
                                    <p>No PDF file uploaded yet.</p>
                                )}
                            </>
                        ) : (
                            <div className="flex justify-center items-center min-h-72 opacity-50">
                                <p>
                                    No preview avalable for{" "}
                                    {selectedFileObj?.file?.name || ""}
                                </p>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className="flex-1 text-sm opacity-50">
                            {selectedFileObj?.file?.name || ""}
                        </div>
                        <Button
                            color="danger"
                            variant="light"
                            onPress={() => setSelectedFileObj(null)}
                            isDisabled={mutation.isPending}
                        >
                            Close
                        </Button>
                        <Button
                            color="secondary"
                            // onPress={() => handleSubmit()}
                            onPress={() => setSelectedFileObj(null)}
                            isLoading={mutation.isPending}
                            isDisabled={mutation.isPending}
                        >
                            OK
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

const scanningFileTypes = [
    "Other",
    "Statement of Facts (SOF)",
    "Notice of Readiness (NOR)",
    "Bill of Lading (BL)",
    "Ship / Shore Safety Check List",
    "Surveyors Report",
    "Line of Position (LOP) ",
    "Vessel Experience Factor (VEF) table",
    "Loading Protocol",
    "Monitoring sheet of Hourly Loading and Back Pressure rate",
    "Ships Ullage Report after loading",
];
