import {
    BreadcrumbItem,
    Breadcrumbs,
    Card,
    CardBody,
    Listbox,
    ListboxItem,
} from "@nextui-org/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ScanningStatus from "src/components/ScanningStatus";
import { Scanning } from "src/types";
import { getScannings } from "src/utils/api";

const voyages = Array.from({ length: 20 }, (_, i) => String(i + 1));

export default function Voyages() {
    const navigate = useNavigate();
    const query = useQuery({
        queryKey: ["scannings"],
        queryFn: () => getScannings(),
        refetchInterval: 15000,
    });

    return (
        <div className="container mx-auto px-3 my-8 pb-8 max-w-screen-xl flex flex-col gap-8">
            <Breadcrumbs>
                <BreadcrumbItem href="/">Home</BreadcrumbItem>
            </Breadcrumbs>
            <div className="flex gap-8 justify-between items-stretch flex-wrap md:flex-nowrap">
                <Card className="flex-grow">
                    <CardBody>
                        <Listbox variant="flat" aria-label="Voyages">
                            {voyages.map((voyageId) => {
                                const scannings = query.data?.filter(
                                    (scanning) =>
                                        scanning.voyage_id === Number(voyageId)
                                );
                                return (
                                    <ListboxItem
                                        key={voyageId}
                                        description={
                                            scannings && scannings?.length > 0
                                                ? `${scannings.length} files`
                                                : null
                                        }
                                        classNames={{
                                            title: "text-medium",
                                            base: "py-3 cursor-pointer",
                                        }}
                                        endContent={
                                            <VoyageStatus
                                                scannings={scannings || []}
                                            />
                                        }
                                        onClick={() => {
                                            navigate(`/voyages/${voyageId}`);
                                        }}
                                    >
                                        Voyage {voyageId}
                                    </ListboxItem>
                                );
                            })}
                        </Listbox>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

// ================================================================================

interface VoyageStatusProps {
    scannings: Scanning[];
}
const VoyageStatus: React.FC<VoyageStatusProps> = ({ scannings }) => {
    return (
        <div className="flex gap-2 items-center">
            {scannings.map((scanning) => (
                <ScanningStatus
                    key={scanning.id}
                    status={scanning.status}
                    isIconOnly
                    tooltip={<><b>{scanning.status}</b> ({scanning.filename})</>}
                />
            ))}
        </div>
    );
};
