import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import { IRegulation } from "src/types";
import formatDate from "src/utils/formatDate";
import RegulationCountry from "./RegulationCountry";
import RegulationStakeholders from "./RegulationStakeholders";
import RegulationStatus from "./RegulationStatus";

export default function RegulationContainer({
  regulations,
  selectedKey,
  hoveredRegulation,
  onHover,
  viewType,
  setSelectedRegulation,
  regulationsExpanded,
}: {
  regulations: IRegulation[];
  selectedKey?: number;
  hoveredRegulation?: IRegulation | null;
  onHover?: (regulation: IRegulation | null) => void;
  viewType?: "list" | "grid";
  setSelectedRegulation: (regulation: IRegulation | null) => void;
  regulationsExpanded?: boolean;
}) {
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >({
    column: "raw_jurisdiction",
    direction: "ascending",
  });

  useEffect(() => {
    if (hoveredRegulation) {
      const row = document.getElementById(
        `regulation-row-${hoveredRegulation.id}`
      );
      const parentContainer =
        row?.closest("table")?.parentElement || // for table view
        row?.closest(".grid")?.parentElement; // for grid view

      if (!row) {
        return console.error("Row not found for regulation", hoveredRegulation);
      }

      if (!parentContainer) {
        return console.error(
          "Parent container not found for regulation",
          hoveredRegulation
        );
      }

      const rect = row.getBoundingClientRect();
      const parentRect = parentContainer.getBoundingClientRect();

      const isInViewport =
        rect.top <= parentRect.top && rect.bottom >= parentRect.bottom;

      if (!isInViewport) {
        parentContainer.scrollTo({
          top: row.offsetTop - 70,
          behavior: "smooth",
        });
      }
    }
  }, [hoveredRegulation]);

  const sortedRegulations: IRegulation[] = useMemo(() => {
    const newSortedRegulations = [...regulations].sort((a, b) => {
      switch (sortDescriptor?.column) {
        case "raw_jurisdiction":
          return a.raw_jurisdiction.localeCompare(b.raw_jurisdiction);
        case "raw_status_of_regulation":
          return a.raw_status_of_regulation.localeCompare(
            b.raw_status_of_regulation
          );
        case "raw_short_name":
          return a.raw_short_name.localeCompare(b.raw_short_name);
        case "raw_entry_into_force_date":
          return a.raw_entry_into_force_date?.localeCompare(
            b.raw_entry_into_force_date
          );
        default:
          console.error("Unknown column", sortDescriptor?.column);
          return -1;
      }
    });
    if (sortDescriptor?.direction === "descending") {
      newSortedRegulations.reverse();
    }
    return newSortedRegulations;
  }, [regulations, sortDescriptor]);

  const handleMouseEnter = (regulation: IRegulation) => {
    onHover?.(regulation);
  };

  const handleMouseLeave = () => {
    onHover?.(null);
  };

  const handleSortChange = (descriptor: SortDescriptor) => {
    setSortDescriptor(descriptor);
  };

  if (viewType === "list") {
    return (
      <Table
        isHeaderSticky
        selectionMode="single"
        aria-label="Example static collection table"
        className={`transition-size ${
          regulationsExpanded ? "h-[70vh]" : "h-[35vh]"
        }`}
        selectedKeys={selectedKey ? [selectedKey] : []}
        sortDescriptor={sortDescriptor}
        onSortChange={handleSortChange}
      >
        <TableHeader>
          <TableColumn> </TableColumn>
          <TableColumn allowsSorting key="raw_jurisdiction">
            JURISTICTION
          </TableColumn>
          <TableColumn allowsSorting key="raw_status_of_regulation">
            STATUS
          </TableColumn>
          <TableColumn allowsSorting key="raw_short_name" className="min-w-52">
            NAME
          </TableColumn>
          <TableColumn allowsSorting key="raw_entry_into_force_date">
            ENTRY INTO FORCE
          </TableColumn>
          <TableColumn>RELEVANT STAKEHOLDER</TableColumn>
        </TableHeader>
        <TableBody>
          {sortedRegulations.map((regulation) => {
            const isHovered = regulation.id === hoveredRegulation?.id;
            const borderClass = isHovered ? "bg-secondary/15" : "";
            return (
              <TableRow
                id={`regulation-row-${regulation.id}`}
                key={regulation.id}
                onMouseEnter={() => handleMouseEnter(regulation)}
                onMouseLeave={handleMouseLeave}
                className={`cursor-pointer active:bg-secondary/15 transition-colors ${borderClass}`}
                onClick={() => {
                  console.log("Row clicked", regulation);
                  setSelectedRegulation(regulation);
                }}
              >
                <TableCell>
                  <RegulationCountry regulation={regulation} />
                </TableCell>
                <TableCell>{regulation.raw_jurisdiction}</TableCell>
                <TableCell>
                  <RegulationStatus regulation={regulation} />
                </TableCell>
                <TableCell>{regulation.raw_short_name}</TableCell>
                <TableCell>
                  {regulation.raw_entry_into_force_date
                    ? formatDate(regulation.raw_entry_into_force_date)
                    : "N/A"}
                </TableCell>
                <TableCell>
                  <RegulationStakeholders regulation={regulation} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  return (
    <div
      className={`overflow-scroll transition-size ${
        regulationsExpanded ? "h-[70vh]" : "h-[35vh]"
      }`}
    >
      <div className="grid grid-cols-12 gap-6 p-6">
        {regulations.map((regulation) => {
          const isHovered = regulation.id === hoveredRegulation?.id;
          const borderClass = isHovered ? "border-2 border-secondary-500" : "";
          const randomImg = regulation.id % 5;

          return (
            <Card
              key={regulation.id}
              id={`regulation-row-${regulation.id}`}
              isFooterBlurred
              className={`min-h-[350px] col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 p-0 ${borderClass}`}
              onMouseEnter={() => handleMouseEnter(regulation)}
              onMouseLeave={handleMouseLeave}
              isPressable
              onPress={() => {
                console.log("card clicked", regulation);
                setSelectedRegulation(regulation);
              }}
            >
              <CardHeader className="absolute z-10 top-0 h-12 flex justify-between items-start gap-3 overflow-hidden">
                <p className="text-left text-nowrap opacity/60 uppercase font-bold">
                  {regulation.raw_jurisdiction}
                </p>
                <RegulationCountry regulation={regulation} />
              </CardHeader>
              <CardBody className="absolute z-10 top-12 bottom-24 flex flex-col gap-2 overflow-hidden">
                <p className="text-sm">{regulation.raw_short_name}</p>
                <span className="flex-1"></span>
                <p className="text-tiny uppercase opacity-60">Stakeholders</p>
                <div>
                  <RegulationStakeholders regulation={regulation} />
                </div>
              </CardBody>
              <div className="z-0 w-full h-full relative">
                <Image
                  removeWrapper
                  alt="Relaxing app background"
                  className="z-0 w-full h-full object-cover"
                  src={`/mock-card/${randomImg}.png`}
                />
                <div className="absolute inset-0 bg-white dark:bg-black opacity-85 dark:opacity-75"></div>
              </div>
              <CardFooter className="flex flex-col justify-between items-start absolute h-24 bg-white/30 dark:bg-black/30 bottom-0 z-10">
                <div>
                  <span className="text-tiny text-slate-600 dark:text-slate-400 uppercase">
                    Entry into force:
                  </span>
                  <span className="text-tiny ml-2">
                    {regulation.raw_entry_into_force_date
                      ? formatDate(regulation.raw_entry_into_force_date)
                      : "N/A"}
                  </span>
                </div>
                <div className="w-full flex justify-between items-center">
                  <RegulationStatus regulation={regulation} />
                  <Button
                    size="sm"
                    as="span"
                    radius="full"
                    variant="light"
                    className="opacity-60"
                    onClick={() => {
                      console.log("Row clicked", regulation);
                      setSelectedRegulation(regulation);
                    }}
                  >
                    Details
                  </Button>
                </div>
              </CardFooter>
            </Card>
          );
        })}
      </div>
    </div>
  );
}
