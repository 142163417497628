import {
    BreadcrumbItem,
    Breadcrumbs,
    Button,
    Card,
    CardBody,
    CircularProgress,
    Listbox,
    ListboxItem,
} from "@nextui-org/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
    createShellvoy6,
    getScannings,
    getVoyageDemurrageInfo,
    scanDocuments,
} from "src/utils/api";
import VoyageDemurrageForm from "./VoyageDemurrageForm";
import ScanningStatus from "src/components/ScanningStatus";

export default function VoyageDemurrageInfoPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    const scanningsQ = useQuery({
        queryKey: ["scannings", id],
        queryFn: () => getScannings(id as string),
    });
    const demurrageInfoQ = useQuery({
        queryKey: ["voyages", id, "demurrage-info"],
        queryFn: () => getVoyageDemurrageInfo(id as string),
        refetchInterval: 10000,
    });
    const scanDocumentsM = useMutation({
        mutationFn: () => scanDocuments(id as string),
        onSuccess: (data) => {
            console.log("onSuccess", data);
            demurrageInfoQ.refetch();
            scanningsQ.refetch();
        },
    });
    const createShellvoy6M = useMutation({
        mutationFn: () => createShellvoy6(id as string),
        onSuccess: (data) => {
            console.log("onSuccess", data);
            demurrageInfoQ.refetch();
            scanningsQ.refetch();
        },
    });
    console.log("query.data", demurrageInfoQ.data);

    if (demurrageInfoQ.isPending || scanningsQ.isPending) {
        return (
            <div className="w-full flex items-center justify-center min-h-32">
                <CircularProgress aria-label="Loading" color="secondary" />
            </div>
        );
    }

    const scannings = scanningsQ.data;
    const isScanning = scannings?.some(
        (scanning) => scanning.status === "SCANNING"
    );
    const isTextracting = scannings?.some(
        (scanning) => scanning.status === "TEXTRACTING"
    );
    const isError = scannings?.some((scanning) => scanning.status === "ERROR");
    const isScanned = scannings?.every(
        (scanning) => scanning.status === "SCANNED"
    );

    return (
        <div className="container mx-auto px-3 my-8 pb-8 max-w-screen-xl flex flex-col gap-8">
            <Breadcrumbs>
                <BreadcrumbItem href="/">Home</BreadcrumbItem>
                <BreadcrumbItem href={`/voyages/${id}`}>
                    Voyage {id}
                </BreadcrumbItem>
                <BreadcrumbItem href={`/voyages/${id}/demurrage-form`}>
                    Demurrage Form
                </BreadcrumbItem>
            </Breadcrumbs>
            <div>
                {!(isScanning || isTextracting) && scanningsQ.data && (
                    <Card className="py-4 md:px-60">
                        <CardBody>
                            <div className="flex flex-col gap-4 items-stretch">
                                {!isScanned && (
                                    <Button
                                        type="button"
                                        // type="submit"
                                        color="secondary"
                                        size="lg"
                                        className="mb-4"
                                        isLoading={
                                            scanDocumentsM.isPending ||
                                            isScanning
                                        }
                                        // isDisabled={calculateFormM.isSuccess}
                                        onPress={() => {
                                            scanDocumentsM.mutate();
                                        }}
                                    >
                                        Scan Documents to Get Form Values
                                    </Button>
                                )}
                                {demurrageInfoQ.data && (
                                    <VoyageDemurrageForm
                                        scannings={scanningsQ.data}
                                        demurrageInfo={demurrageInfoQ.data}
                                    />
                                )}
                            </div>
                            {isScanned && (
                                <Button
                                    color="warning"
                                    size="lg"
                                    className="mt-4 h-14"
                                    // isLoading={updateVoyageInfoM.isPending}
                                    // isDisabled={calculateDemurrageM.isSuccess}
                                    role="link"
                                    onPress={() => {
                                        navigate(
                                            `/voyages/${id}/demurrage-calculator`
                                        );
                                    }}
                                >
                                    Demurrage Calculator
                                </Button>
                            )}
                            {isScanned && (
                                <Button
                                    color="warning"
                                    size="lg"
                                    className="mt-4 h-14"
                                    isLoading={createShellvoy6M.isPending}
                                    onPress={() => {
                                        createShellvoy6M.mutate();
                                    }}
                                >
                                    Create Shellvoy6
                                </Button>
                            )}
                            {createShellvoy6M.isSuccess && (
                                <div className="mt-4">
                                    <p className="text-green-600">
                                        Email has been sent successfully!
                                    </p>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                )}
                <br />
                {(isScanning || isTextracting || isError) && (
                    <Listbox
                        variant="flat"
                        aria-label="Listbox menu with descriptions"
                    >
                        {(scannings || []).map((scanning) => {
                            return (
                                <ListboxItem
                                    key={scanning.id}
                                    endContent={
                                        <ScanningStatus
                                            status={scanning.status}
                                        />
                                    }
                                >
                                    {scanning.filename}
                                </ListboxItem>
                            );
                        })}
                    </Listbox>
                )}
            </div>
        </div>
    );
}
