import { Avatar, AvatarProps } from "@nextui-org/react";
import { IProject } from "src/types";

export default function ProjectCountry({
  project,
  ...props
}: AvatarProps & {
  project: IProject;
}) {
  const countryCode = project.country_code;
  if (!countryCode || countryCode.length !== 2) {
    return null;
  }

  return (
    <Avatar
      alt={countryCode}
      className="w-6 h-6"
      src={`https://flagcdn.com/${countryCode.toLowerCase()}.svg`}
      {...props}
    />
  );
}
