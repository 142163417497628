import {
    Spinner
} from "@nextui-org/react";
import {
    Outlet,
    useNavigation
} from "react-router-dom";
import SarsNavbar from "./SarsNavbar";

export default function MultiPurposeMapLayout() {
    const navigation = useNavigation();

    return (
        <div className="h-full w-full bg-gradient-to-br from-rose-400 via-fuchsia-500 to-indigo-500">
            {navigation.state === "loading" ? (
                <div className="flex h-full w-full items-center justify-center bg-gray-200/75 dark:bg-gray-900/90">
                    <Spinner
                        size="lg"
                        color="secondary"
                        labelColor="secondary"
                    />
                </div>
            ) : (
                <div className="h-full w-full bg-gray-200/75 dark:bg-gray-900/90">
                    <SarsNavbar />
                    <Outlet />
                </div>
            )}
        </div>
    );
}
