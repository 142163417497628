import { DateValue } from "@nextui-org/react";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { IRegulation }from "src/types";
import getJuristictionCode from "src/utils/getJuristictionCode";
import { useToggle } from "usehooks-ts";

export const countryOptions = [
  { key: "AR", label: "Argentina", continent: "South America" },
  { key: "BT", label: "Bhutan", continent: "Asia" },
  { key: "BR", label: "Brazil", continent: "South America" },
  { key: "KH", label: "Cambodia", continent: "Asia" },
  { key: "CO", label: "Colombia", continent: "South America" },
  { key: "CL", label: "Chile", continent: "South America" },
  { key: "CR", label: "Costa Rica", continent: "North America" },
  { key: "EC", label: "Ecuador", continent: "South America" },
  { key: "FR", label: "France", continent: "Europe" },
  { key: "GH", label: "Ghana", continent: "Africa" },
  { key: "HN", label: "Honduras", continent: "North America" },
  { key: "IN", label: "India", continent: "Asia" },
  { key: "ID", label: "Indonesia", continent: "Asia" },
  { key: "TZ", label: "Tanzania", continent: "Africa" },
  { key: "KE", label: "Kenya", continent: "Africa" },
  { key: "MY", label: "Malaysia", continent: "Asia" },
  { key: "PA", label: "Panama", continent: "North America" },
  { key: "PY", label: "Paraguay", continent: "South America" },
  { key: "PE", label: "Peru", continent: "South America" },
  { key: "RW", label: "Rwanda", continent: "Africa" },
  { key: "SG", label: "Singapore", continent: "Asia" },
  { key: "ZA", label: "South Africa", continent: "Africa" },
  { key: "ZM", label: "Zambia", continent: "Africa" },
  { key: "TH", label: "Thailand", continent: "Asia" },
  { key: "UG", label: "Uganda", continent: "Africa" },
  { key: "GB", label: "United Kingdom", continent: "Europe" },
  { key: "US", label: "United States", continent: "North America" },
  { key: "ZW", label: "Zimbabwe", continent: "Africa" },
];

export const stakeholderOptions = [
  "Project Developers",
  "Validation and Verification Bodies",
  "Carbon Credit Buyers",
  "Independent Crediting Standards",
  "Carbon Project Developers",
];

const filterRegulations = (
  regulations: IRegulation[],
  filter: Filter
): IRegulation[] => {
  return regulations.filter((regulation) => {
    if (filter.query) {
      const text = `${regulation.raw_jurisdiction} ${regulation.raw_short_name} ${regulation.raw_status_of_regulation} ${regulation.raw_stakeholders}`;
      if (!text.toLowerCase().includes(filter.query.toLowerCase())) {
        return false;
      }
    }

    if (filter.countries && filter.countries.size > 0) {
      if (!filter.countries.has(getJuristictionCode(regulation) || "")) {
        return false;
      }
    }

    if (filter.statuses && filter.statuses.size > 0) {
      const applies = Array.from(filter.statuses).some((status) => {
        return regulation.raw_status_of_regulation.startsWith(status);
      });
      if (!applies) {
        return false;
      }
    }

    if (filter.entryIntoForce) {
      if (
        !regulation.raw_entry_into_force_date ||
        new Date(regulation.raw_entry_into_force_date) <
          // @ts-expect-error @internationalized library
          filter.entryIntoForce.start.toDate() ||
        new Date(regulation.raw_entry_into_force_date) >
          // @ts-expect-error @internationalized library
          filter.entryIntoForce.end.toDate()
      ) {
        return false;
      }
    }

    if (filter.stakeholders && filter.stakeholders.size > 0) {
      const stakeholders = regulation.raw_stakeholders
        .split(/[,\-\n]/)
        .map((x) => x.replace("\n", ""))
        .map((x) => x.trim())
        .filter(Boolean);

      const applies = Array.from(filter.stakeholders).every((stakeholder) => {
        return stakeholders.includes(stakeholder);
      });
      if (!applies) {
        return false;
      }
    }

    return true;
  });
};

export interface Filter {
  query: string;
  countries?: Set<string>;
  continent?: Set<string>;
  statuses?: Set<string>;
  entryIntoForce?: null | {
    start: DateValue;
    end: DateValue;
  };
  stakeholders?: Set<string>;
}

const defaultFilter: Filter = {
  query: "",
  countries: new Set([]),
  continent: new Set([]),
  statuses: new Set([]),
  entryIntoForce: null,
  stakeholders: new Set([]),
};

export interface FilterHook {
  filter: Filter;
  setFilter: (filter: Filter) => void;
  clear: () => void;
  update: (filterPart: Partial<Filter>) => void;
  filteredRegulations: IRegulation[];
  toggle: [boolean, () => void, Dispatch<SetStateAction<boolean>>];
}

export const useFilter = ({
  regulations,
}: {
  regulations: IRegulation[];
}): FilterHook => {
  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const toggle = useToggle();

  const clear = () => setFilter({ ...defaultFilter });
  const update = (filterPart: Partial<Filter>) =>
    setFilter({ ...filter, ...filterPart });

  const filteredRegulations = useMemo(() => {
    const filteredRegulations = filterRegulations(regulations, filter);
    return filteredRegulations;
  }, [regulations, filter]);

  return {
    filter,
    setFilter,
    clear,
    update,
    filteredRegulations,
    toggle,
  };
};
