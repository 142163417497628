import { Select, SelectItem } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";

export default function VoyageSelect() {
    const { id } = useParams();
    const voyages = Array.from({ length: 20 }, (_, i) => String(i + 1));
    const navigate = useNavigate();

    return (
        <Select
            selectedKeys={id ? [String(id)] : []}
            onChange={(e) => {
                navigate(`/voyages/${e.target.value}`);
            }}
            size="lg"
            label="Voyage"
            selectionMode="single"
            className="min-w-40"
        >
            {voyages.map((voyage) => (
                <SelectItem
                    key={voyage}
                    value={voyage}
                    textValue={`Voyage ${voyage}`}
                    className="min-h-12"
                >
                    Voyage {voyage}
                </SelectItem>
            ))}
        </Select>
    );
}
