import { Chip, ChipProps } from "@nextui-org/react";
import { IRegulation }from "src/types";

export default function RegulationStatus({
  regulation,
  status,
}: {
  regulation?: IRegulation;
  status?: string;
}) {
  const statusText = regulation?.raw_status_of_regulation || status || "Not applicable";
  let color: ChipProps["color"] = "default";

  if (statusText === "In force") {
    color = "default";
  } else if (statusText.startsWith("Proposed")) {
    color = "warning";
  } else {
    color = "danger";
  }

  return (
    <Chip
      className="capitalize"
      color={color}
      variant="flat"
      classNames={{
        base: statusText === "In force" ? "bg-teal-400/20" : "",
      }}
    >
      {statusText}
    </Chip>
  );
}
