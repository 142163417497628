import { IProject } from "src/types";
import ProjectTypeArIcon from "src/assets/project-type/project-type-ar.svg?react";
import ProjectTypeBiogasIcon from "src/assets/project-type/project-type-biogas.svg?react";
import ProjectTypeEnergyEfficiencyIcon from "src/assets/project-type/project-type-energy-efficiency.svg?react";
import ProjectTypeOtherIcon from "src/assets/project-type/project-type-other.svg?react";
import ProjectTypePvIcon from "src/assets/project-type/project-type-pv.svg?react";

export default function ProjectType({
  project,
}: // status,
{
  project?: IProject;
  // status?: string;
}) {
  const typeParts = project?.type?.split(" - ") || [];
  console.log(`ProjectType: ${project?.type} => `, typeParts);

  return (
    <>
      <span className="text-nowrap">{typeParts[0]}</span>
      {typeParts[1] && (
        <>
          <br />
          <span className="text-sm opacity-50 text-nowrap">{typeParts[1]}</span>
        </>
      )}
    </>
  );
}

export function ProjectTypeIcon({ project }: { project: IProject }) {
  const typeParts = project?.type?.split(" - ") || [];

  const className: React.ComponentProps<"svg">["className"] = "w-7 h-7 fill-black/50 dark:fill-white/50";

  switch (typeParts[0]) {
    case "Energy Efficiency":
      return <ProjectTypeEnergyEfficiencyIcon className={className} />;
    case "Biogas":
      return <ProjectTypeBiogasIcon className={className} />;
    case "PV":
      return <ProjectTypePvIcon className={className} />;
    case "A/R":
      return <ProjectTypeArIcon className={className} />;
    case "Other":
    default:
      return <ProjectTypeOtherIcon className={className} />;
  }
}

ProjectType.Icon = ProjectTypeIcon;
