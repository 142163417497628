import {
    BreadcrumbItem,
    Breadcrumbs,
    Button,
    Card,
    CardBody,
    CardHeader,
    CircularProgress,
    Listbox,
    ListboxItem,
} from "@nextui-org/react";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VoyageSelect from "src/components/VoyageSelect";
import { Scanning } from "src/types";
import { getScannings } from "src/utils/api";
import formatDateTime from "src/utils/formatDateTime";
import NewVoyageFile from "./NewVoyageFile";
import ScanningStatus from "src/components/ScanningStatus";

export default function Voyage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const query = useQuery({
        queryKey: ["scannings", id],
        queryFn: () => getScannings(id as string),
        refetchInterval: 15000,
    });
    console.log("query.data", query.data);

    const groupedScannings: Record<string, Scanning[]> = useMemo(() => {
        const acc: Record<string, Scanning[]> = {};
        for (const scanning of query.data || []) {
            const fileType = scanning.file_type || "Other";

            if (!acc[fileType]) {
                acc[fileType] = [];
            }
            acc[fileType].push(scanning);
        }
        return acc;
    }, [query.data]);

    if (query.isPending) {
        return (
            <div className="w-full flex items-center justify-center min-h-32">
                <CircularProgress aria-label="Loading" color="secondary" />
            </div>
        );
    }

    // const scannings = query.data;
    // const isTextracting = scannings?.some(scanning => scanning.status === "TEXTRATING")
    // const isScanning = scannings?.some(scanning => scanning.status === "SCANNING")

    return (
        <div>
            <div className="container mx-auto px-3 my-8 pb-8 max-w-screen-xl flex flex-col gap-8">
                <Breadcrumbs>
                    <BreadcrumbItem href="/">Home</BreadcrumbItem>
                    <BreadcrumbItem href={`/voyages/${id}`}>
                        Voyage {id}
                    </BreadcrumbItem>
                </Breadcrumbs>
                <div className="flex gap-8 justify-between items-stretch flex-wrap md:flex-nowrap">
                    <div className="flex-grow">
                        <NewVoyageFile voyageId={String(id)} />
                    </div>
                    <Card className="flex-grow md:flex-grow-0">
                        <CardBody className="flex-grow">
                            <div className="h-full flex flex-col gap-4 items-stretch md:min-w-72">
                                <VoyageSelect />
                                <div className="flex-grow"/>
                                <Button
                                    role="link"
                                    color="secondary"
                                    size="lg"
                                    href={`/voyages/${id}/demurrage-form`}
                                    // isDisabled={isTextracting || isScanning}
                                    onPress={() => {
                                        navigate(
                                            `/voyages/${id}/demurrage-form`
                                        );
                                    }}
                                >
                                    Calculate Demurrage
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <h3 className="text-sm uppercase opacity-60">
                    Scanned Files of <b>Voyage {id}</b>
                </h3>
                {Object.entries(groupedScannings).map(
                    ([fileType, scannings]) => {
                        return (
                            <div key={fileType}>
                                <Card className="w-full">
                                    <CardHeader className="text-sm uppercase opacity-60">
                                        {fileType}
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <Listbox
                                                variant="flat"
                                                aria-label="Listbox menu with descriptions"
                                            >
                                                {scannings.map((scanning) => {
                                                    return (
                                                        <ListboxItem
                                                            key={scanning.id}
                                                            description={formatDateTime(
                                                                scanning.created_at
                                                            )}
                                                            endContent={<ScanningStatus status={scanning.status}/>}
                                                            onClick={() => {
                                                                navigate(
                                                                    `/voyages/${id}/files/${scanning.id}`
                                                                );
                                                            }}
                                                            classNames={{
                                                                title: "text-medium",
                                                                base: "py-4 cursor-pointer",
                                                            }}
                                                        >
                                                            {scanning.filename}
                                                        </ListboxItem>
                                                    );
                                                })}
                                            </Listbox>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        );
                    }
                )}
            </div>
        </div>
    );
}
