import { CircularProgress, Tooltip } from "@nextui-org/react";
import { ReactNode } from "react";

interface ScanningStatusProps {
    status: string;
    isIconOnly?: boolean;
    tooltip?: string | ReactNode;
}
export default function ScanningStatus({
    status,
    isIconOnly,
    tooltip,
}: ScanningStatusProps) {
    let icon = null;
    if (status === "UPLOADING")
        icon = (
            <CircularProgress color="default" size="sm" aria-label={status} />
        );
    if (status === "UPLOADED")
        icon = (
            <span className="iconify mdi--check w-8 h-8 bg-green-500"></span>
        );
    if (status === "TEXTRACTING")
        icon = (
            <CircularProgress color="warning" size="sm" aria-label={status} />
        );
    if (status === "TEXTRACTED")
        icon = <span className="iconify mdi--check w-8 h-8"></span>;
    if (status === "SCANNING")
        icon = (
            <CircularProgress color="success" size="sm" aria-label={status} />
        );
    if (status === "SCANNED")
        icon = (
            <span className="iconify mdi--check w-8 h-8 bg-green-500"></span>
        );
    if (status === "ERROR")
        icon = <span className="iconify mdi--error w-8 h-8 bg-red-600"></span>;

    if (isIconOnly) {
        return (
            <Tooltip content={tooltip || status} showArrow aria-label={status}>
                {icon}
            </Tooltip>
        );
    }

    return (
        <div className="flex items-center gap-3">
            <span className="opacity-50">{status}</span>
            <span>{icon}</span>
        </div>
    );
}
