import { LuzmoDashboardComponent } from "@luzmo/react-embed";
import { BreadcrumbItem, Breadcrumbs, Spinner } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useNavigation, useParams } from "react-router-dom";

export default function VoyageLuzmo() {
    const { id } = useParams()
    const navigation = useNavigation();
    const [authKey, setAuthKey] = useState<string | null>(null);
    const [authToken, setAuthToken] = useState<string | null>(null);
    const [dashboardId, setDashboardId] = useState<string | null>(null);

    useEffect(() => {
        function fetchAccessToken(email: string, password: string) {
            fetch("https://admin.dataritim.com/auth/jwt/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            })
                .then((response) => {
                    if (response.status === 401) {
                        console.error(response);
                    } else if (response.ok) {
                        return response.json();
                    }
                    throw new Error("Network response was not ok.");
                })
                .then((data) => {
                    localStorage.setItem("accessToken", data.access);
                    console.log("Access token:", data.access);
                    fetchPanoDetails(data.access);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }

        function fetchPanoDetails(token: string) {
            // let params = "";
            // if (id) {
            //     params = `?9e4a8ae1-5e92-484b-bfb9-c74ba78d9dbc=${id}`;
            // }

            const url = `https://admin.dataritim.com/api/pano/214?adbdbd5b-d2f7-4b13-9253-74db5b3bb2da=Voyage ${id}`
            console.log("Fetching pano details from", url);


            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `JWT ${token}`,
                },
            })
                .then((response) => {
                    if (response.ok) return response.json();
                    throw new Error("Failed to fetch pano details");
                })
                .then((data) => {
                    console.log("Pano details:", data);

                    setAuthKey(data.embed_token.key);
                    setAuthToken(data.embed_token.token);
                    setDashboardId(data.dashboard_id);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }

        console.log("Luzmo mounted with id", { id });
        fetchAccessToken("gs@dataritim.com", "3276evhWTduKw5w");
    }, [id]);

    const actUponCustomEventsEvent = (exportedEvent: Event) => {
        console.log("Custom event received", exportedEvent);
    };

    return (
        <div className="h-full w-full bg-white">
            {navigation.state === "loading" ? (
                <div className="flex h-full w-full items-center justify-center bg-white">
                    <Spinner size="lg" color="secondary" labelColor="secondary" />
                </div>
            ) : (
                <div className="h-full w-full bg-white">
                    <div className="bg-neutral-700 px-4 py-3">
                        <Breadcrumbs id="asdfg">
                            <BreadcrumbItem href="/">Home</BreadcrumbItem>
                            <BreadcrumbItem href={`/voyages/${id}`}>Voyage {id}</BreadcrumbItem>
                            <BreadcrumbItem href={`/voyages/${id}/demurrage-form`}>
                                Demurrage Form
                            </BreadcrumbItem>
                            <BreadcrumbItem href={`/voyages/${id}/demurrage-calcultor`}>
                                Demurrage Calculater
                            </BreadcrumbItem>
                        </Breadcrumbs>
                    </div>

                    <div className="container max-w-[769px] mx-auto">
                        {authKey && authToken && dashboardId ? (
                            <LuzmoDashboardComponent
                                appServer="https://app.luzmo.com"
                                apiHost="https://api.luzmo.com"
                                authKey={authKey}
                                authToken={authToken}
                                dashboardId={dashboardId}
                                customEvent={actUponCustomEventsEvent}
                            ></LuzmoDashboardComponent>
                        ) : (
                            <div className="flex min-h-[300px] justify-center items-center">
                                <Spinner size="lg" color="secondary" labelColor="secondary" />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
