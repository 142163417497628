import * as Sentry from "@sentry/react";
import { NextUIProvider } from "@nextui-org/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import Tracker from '@openreplay/tracker';
import "./index.css";
import QueryClientProvider from "./components/QueryClientProvider.tsx";
import { ThemeSwitcherProvider } from "./components/ThemeSwitcher.tsx";
import "./index.css";
import router from "./router.tsx";

Sentry.init({
  dsn: "https://4e15ecb8d55f750e4aead6ee6e241b8b@o4507837978312704.ingest.de.sentry.io/4507837981196368",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://demo.kagansari.com"],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const tracker = new Tracker({
  projectKey: "BRX1stl9FtiMhKxuGMTQ",  
  obscureTextEmails: false,
  obscureInputEmails: false,
});
tracker.start();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <NextUIProvider>
      <QueryClientProvider>
        <ThemeSwitcherProvider>
          <RouterProvider router={router} />
        </ThemeSwitcherProvider>
      </QueryClientProvider>
    </NextUIProvider>
  </StrictMode>
);
