import { Avatar, AvatarProps } from "@nextui-org/react";
import { IRegulation }from "src/types";
import getJuristictionCode from "src/utils/getJuristictionCode";

export default function RegulationCountry({
  regulation,
  ...props
}: AvatarProps & {
  regulation: IRegulation;
}) {
  const countryCode = getJuristictionCode(regulation);
  if (!countryCode || countryCode.length !== 2) {
    return null;
  }

  return (
    <Avatar
      alt={countryCode}
      className="w-6 h-6"
      src={`https://flagcdn.com/${countryCode.toLowerCase()}.svg`}
      {...props}
    />
  );
}
