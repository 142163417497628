import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { getMe } from "src/utils/api";
import { tracker } from "src/main";

interface User {
  id: number;
  email: string;
  role: "ADMIN" | "GOLD_STANDARD" | "CHARTERER"
}
const useMe = () => {
  const query = useQuery<User>({ queryKey: ["me"], queryFn: getMe });
  const me = query.data;

  useEffect(() => {
    if (me?.id) {
      Sentry.setUser({
        id: me.id,
        email: me.email
      });
      tracker.setUserAnonymousID(String(me.id));
      tracker.setUserID(me.email);
      // @ts-expect-error hotjar
      window.hj('identify', me.id, {
        email: me.email
      });
    }
  }, [me?.id, me?.email])

  const isAdmin = me?.role === "ADMIN";
  const isGS = me?.role === "GOLD_STANDARD" || isAdmin;
  const isCharterer = me?.role === "CHARTERER" || isAdmin;

  return { ...query, me, isAdmin, isGS, isCharterer };
};

export default useMe;
