import { Tooltip } from "@nextui-org/react";
import { FunctionComponent, SVGProps } from "react";
import SdgIcon1 from "src/assets/sdg/1.svg?react";
import SdgIcon10 from "src/assets/sdg/10.svg?react";
import SdgIcon11 from "src/assets/sdg/11.svg?react";
import SdgIcon12 from "src/assets/sdg/12.svg?react";
import SdgIcon13 from "src/assets/sdg/13.svg?react";
import SdgIcon15 from "src/assets/sdg/15.svg?react";
import SdgIcon17 from "src/assets/sdg/17.svg?react";
import SdgIcon2 from "src/assets/sdg/2.svg?react";
import SdgIcon3 from "src/assets/sdg/3.svg?react";
import SdgIcon4 from "src/assets/sdg/4.svg?react";
import SdgIcon5 from "src/assets/sdg/5.svg?react";
import SdgIcon6 from "src/assets/sdg/6.svg?react";
import SdgIcon7 from "src/assets/sdg/7.svg?react";
import SdgIcon8 from "src/assets/sdg/8.svg?react";
import SdgIcon9 from "src/assets/sdg/9.svg?react";
import { IProject } from "src/types";

const sdgIconMap: Record<
  number,
  FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>
> = {
  1: SdgIcon1,
  2: SdgIcon2,
  3: SdgIcon3,
  4: SdgIcon4,
  5: SdgIcon5,
  6: SdgIcon6,
  7: SdgIcon7,
  8: SdgIcon8,
  9: SdgIcon9,
  10: SdgIcon10,
  11: SdgIcon11,
  12: SdgIcon12,
  13: SdgIcon13,
  15: SdgIcon15,
  17: SdgIcon17,
};

export default function SDGIcon({
  sdg,
}: {
  sdg: IProject["sustainable_development_goals"][number];
}) {
  const goalNumberMatch = sdg.name.match(/Goal (\d+):/);
  const goalNumber = goalNumberMatch ? parseInt(goalNumberMatch[1], 10) : null;

  if (!goalNumber) {
    console.error(`Invalid goal number: ${goalNumber}`);
    return null;
  }

  const SvgComp = sdgIconMap[goalNumber];

  return (
    <Tooltip content={sdg.name}>
      <span>
        <SvgComp className="min-w-12 min-h-12 w-12 h-12" />
      </span>
    </Tooltip>
  );
}
