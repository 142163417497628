import { Button, Chip, Input, Tooltip } from "@nextui-org/react";
import { debounce } from "lodash";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTheme } from "src/components/ThemeSwitcher";
import formatDate from "src/utils/formatDate";
import { countryOptions, FilterHook } from "./useFilter";

const countryCodeMap = countryOptions.reduce((acc, country) => {
  acc[country.key] = country.label;
  return acc;
}, {} as Record<(typeof countryOptions)[0]["key"], string>);

function FilterControlBar({ filterHook }: { filterHook: FilterHook }) {
  const { filter, update } = filterHook;

  const chips: { label: string; onClose: () => void }[] = [];
  if (filter.continent?.size) {
    for (const continent of Array.from(filter.continent)) {
      chips.push({
        label: continent,
        onClose: () => {
          update({
            continent: new Set([]),
            countries: new Set([]),
          });
        },
      });
    }
  } else if (filter.countries?.size) {
    for (const country of Array.from(filter.countries)) {
      chips.push({
        label: countryCodeMap[country],
        onClose: () => {
          update({
            countries: new Set(
              Array.from(filter.countries!).filter((c) => c !== country)
            ),
          });
        },
      });
    }
  }

  if (filter.entryIntoForce) {
    const label = `${formatDate(filter.entryIntoForce.start)} - ${formatDate(
      filter.entryIntoForce.end
    )}`;
    chips.push({
      label,
      onClose: () => {
        update({ entryIntoForce: null });
      },
    });
  }

  if (filter.statuses?.size) {
    for (const status of Array.from(filter.statuses)) {
      chips.push({
        label: status,
        onClose: () => {
          update({
            statuses: new Set(
              Array.from(filter.statuses!).filter((s) => s !== status)
            ),
          });
        },
      });
    }
  }

  if (filter.stakeholders?.size) {
    chips.push({
      label: `${filter.stakeholders.size} stakeholders`,
      onClose: () => {
        update({
          stakeholders: new Set([]),
        });
      },
    });
  }

  return (
    <div className="hidden sm:flex overflow-x-scroll justify-between items-center gap-3">
      {chips.map((chip) => {
        return (
          <Chip key={chip.label} size="sm" onClose={chip.onClose}>
            {chip.label}
          </Chip>
        );
      })}
    </div>
  );
}

export default function RegulationControlBar({
  viewType,
  setViewType,
  infoText,
  filterHook,
  regulationsExpanded,
  setRegulationsExpanded,
}: {
  viewType: "list" | "grid";
  setViewType: (viewType: "list" | "grid") => void;
  filterHook: FilterHook;
  infoText?: string;
  regulationsExpanded?: boolean;
  setRegulationsExpanded: Dispatch<SetStateAction<boolean>>;
}) {
  const theme = useTheme();
  const [, toggleFilterVisibility] = filterHook.toggle;
  const [input, setInput] = useState<string>("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnInput = useCallback(
    debounce((v: string) => filterHook.update({ query: v }), 500),
    [filterHook.filter]
  );

  return (
    <div
      className="flex justify-between items-center z-40 gap-3 mx-3 mt-2 pb-2"
      style={{
        boxShadow:
          theme === "light"
            ? "0 15px 10px rgba(255, 255, 255, 0.75)"
            : "0 15px 10px rgba(10, 10, 10, 0.50)",
      }}
    >
      <Button
        isIconOnly
        variant="light"
        onClick={() => setRegulationsExpanded(!regulationsExpanded)}
        className={viewType === "grid" ? "opacity-50" : ""}
      >
        <span
          className={`iconify mdi--chevron-down min-w-7 min-h-7 ${
            regulationsExpanded ? "" : "rotate-180"
          }`}
        ></span>
      </Button>

      <span className="hidden sm:flex text-default-400 text-small text-nowrap">
        {infoText || ""}
      </span>
      <FilterControlBar filterHook={filterHook} />
      <span className="flex-1" />
      <Input
        isClearable
        className="sm:max-w-44"
        placeholder="Search..."
        startContent={
          <span className="iconify mdi--search w-6 h-6 mr-2"></span>
        }
        value={input}
        onClear={() => {
          setInput("");
          filterHook.update({ query: "" });
        }}
        onValueChange={(v) => {
          setInput(v);
          debouncedOnInput(v);
        }}
      />

      <div className="flex">
        <Tooltip content="Grid view" showArrow>
          <Button
            isIconOnly
            variant="light"
            onClick={() => setViewType("grid")}
            className={viewType === "list" ? "opacity-50" : ""}
          >
            <span className="iconify mdi--view-grid-outline min-w-5 min-h-5"></span>
          </Button>
        </Tooltip>
        <Tooltip content="Table view" showArrow>
          <Button
            isIconOnly
            variant="light"
            onClick={() => setViewType("list")}
            className={viewType === "grid" ? "opacity-50" : ""}
          >
            <span className="iconify mdi--format-list-bulleted min-w-5 min-h-5"></span>
          </Button>
        </Tooltip>
        <Button
          variant="light"
          endContent={
            <span className="iconify mdi--tune min-w-5 min-h-5 rotate-90"></span>
          }
          onClick={toggleFilterVisibility}
        >
          Filters
        </Button>
      </div>
    </div>
  );
}
