
import { DateValue } from "@nextui-org/react";

const formatDateTime = (date?: string | Date | DateValue) => {
  return new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // @ts-expect-error DateValue works here
  }).format(new Date(date));
};

export default formatDateTime;