import { Chip } from "@nextui-org/react";
import { IRegulation }from "src/types";

export default function RegulationStakeholders({
  regulation,
}: {
  regulation: IRegulation;
}) {
  if (!regulation.raw_stakeholders) {
    return null;
  }

  const stakeholders = regulation.raw_stakeholders
    .split(/[,\-\n]/)
    .map((x) => x.replace("\n", ""))
    .map((x) => x.trim())
    .filter(Boolean);

  return (
    <span className="flex flex-wrap gap-2">
      {stakeholders.map((stakeholder, index) => (
        <Chip color="default" key={index} size="sm" variant="flat">
          {stakeholder}
        </Chip>
      ))}
    </span>
  );
}
