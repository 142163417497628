import {
    BreadcrumbItem,
    Breadcrumbs,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CircularProgress,
    Input,
    Tab,
    Tabs,
} from "@nextui-org/react";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Scanning } from "src/types";
import { getScanning } from "src/utils/api";
import PdfTab from "./PdfTab";
import "./VoyageFileDetails.css";

export default function VoyageFileDetails() {
    const { id, fileId } = useParams();
    const query = useQuery({
        queryKey: ["scannings", id, "files", fileId],
        queryFn: () => getScanning(fileId as string),
    });
    const [tab, setTab] = React.useState<"pdf" | "values">("pdf");
    const [highlightVisibility, setHighlightVisibility] = useState<
        "opacity-rotate" | "opacity-100" | "opacity-0"
    >("opacity-rotate");

    const scanning = query.data;

    console.log("scanning", scanning);

    if (query.isPending) {
        return (
            <div className="w-full flex items-center justify-center min-h-32">
                <CircularProgress color="secondary" />
            </div>
        );
    }

    if (!scanning) {
        return null;
    }

    return (
        <div className="container mx-auto px-3 my-8 pb-4 max-w-screen-xl flex flex-col gap-8 overflow-y-scroll">
            <Breadcrumbs>
                <BreadcrumbItem href="/">Home</BreadcrumbItem>
                <BreadcrumbItem href={`/voyages/${id}`}>Voyage {id}</BreadcrumbItem>
                <BreadcrumbItem href={`/voyages/${id}files/${scanning.id}`}>
                    {scanning.filename}
                </BreadcrumbItem>
            </Breadcrumbs>

            <Card className="w-full max-h-[80vh]">
                <CardHeader>
                    <div className="w-full flex justify-between items-center">
                        <Tabs
                            color="secondary"
                            aria-label="Tabs colors"
                            selectedKey={tab}
                            onSelectionChange={(v) =>
                                setTab(v as "pdf" | "values")
                            }
                        >
                            <Tab key="pdf" title="PDF" />
                            <Tab key="values" title="Extracted Values" />
                        </Tabs>
                        {tab === "pdf" && (
                            <ButtonGroup>
                                <Button
                                    isIconOnly
                                    onClick={() =>
                                        setHighlightVisibility("opacity-rotate")
                                    }
                                >
                                    <span
                                        className="iconify material-symbols--rotate-auto w-6 h-6"
                                        style={{
                                            opacity:
                                                highlightVisibility ===
                                                    "opacity-rotate"
                                                    ? 1
                                                    : 0.5,
                                        }}
                                    ></span>
                                </Button>
                                <Button
                                    isIconOnly
                                    onClick={() =>
                                        setHighlightVisibility(
                                            highlightVisibility ===
                                                "opacity-100"
                                                ? "opacity-0"
                                                : "opacity-100"
                                        )
                                    }
                                >
                                    <span
                                        className={
                                            highlightVisibility ===
                                                "opacity-100"
                                                ? "iconify mdi--eye w-6 h-6"
                                                : "iconify mdi--eye-off w-6 h-6"
                                        }
                                        style={{
                                            opacity:
                                                highlightVisibility !==
                                                    "opacity-rotate"
                                                    ? 1
                                                    : 0.5,
                                        }}
                                    ></span>
                                </Button>
                            </ButtonGroup>
                        )}
                    </div>
                </CardHeader>
                <CardBody>
                    {tab === "values" && <EditFormTab scanning={scanning} />}
                    {tab === "pdf" && (
                        <PdfTab
                            scanning={scanning}
                            onRefetch={() => query.refetch()}
                            highlightVisibility={highlightVisibility}
                        />
                    )}
                </CardBody>
            </Card>
        </div>
    );
}

// ================================

interface EditFormValues {
    date: string;
    portName: string;
    vesselName: string;
}

const EditFormTab: React.FC<{ scanning: Scanning }> = ({ scanning }) => {
    const defaultValues = scanning.ai_block_mapping_form!;

    const onSubmit: SubmitHandler<EditFormValues> = (data: EditFormValues) => {
        console.log("TODO LoginFormValues", data);
    };

    const {
        control,
        handleSubmit,
        // formState: { errors },
    } = useForm<EditFormValues>({ values: defaultValues });

    return (
        <form
            className="flex flex-col gap-3 w-full max-w-3xl mx-auto mt-3"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Controller
                name="date"
                control={control}
                //   rules={{
                //     required: "Email is required",
                //     pattern: {
                //       value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                //       message: "Invalid email address",
                //     },
                //   }}
                render={({ field }) => (
                    <Input
                        type="input"
                        label="Date"
                        placeholder="Enter date"
                        size="lg"
                        {...field}
                    />
                )}
            />
            <Controller
                name="vesselName"
                control={control}
                render={({ field }) => (
                    <Input
                        type="input"
                        label="Vessel Name"
                        placeholder="Enter vessel name"
                        size="lg"
                        {...field}
                    />
                )}
            />
            <Controller
                name="portName"
                control={control}
                render={({ field }) => (
                    <Input
                        type="input"
                        label="Port Name"
                        placeholder="Enter port name"
                        size="lg"
                        {...field}
                    />
                )}
            />

            <Button
                type="submit"
                size="lg"
                className="h-14 bg-foreground/10 dark:bg-foreground/20 mt-4"
            // isDisabled={mutation.isPending || mutation.isSuccess}
            // isLoading={mutation.isPending}
            >
                Save
            </Button>
        </form>
    );
};
