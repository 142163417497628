import { getCode, overwrite } from "country-list";
import { IRegulation }from "src/types";

overwrite([
  {
    code: "GB",
    name: "United Kingdom",
  },
  {
    code: "ZM",
    name: "Zambia",
  },
  {
    code: "TZ",
    name: "Tanzania",
  },
  {
    code: "US-CA",
    name: "California",
  },
  {
    code: "EU",
    name: "European Union",
  },
]);

export default function getJuristictionCode(
  regulation: IRegulation
): string | null {
  const countryCode = getCode(regulation.raw_jurisdiction);
  return countryCode || null;
}
