import { Button, Input, Link, Tab, Tabs } from "@nextui-org/react";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Scanning, VoyageDemurrageInfo } from "src/types";
import {
    updateVoyageDemurrageInfo,
} from "src/utils/api";

export interface DemurrageInfoCharterPartyFormValues {
    noticeOfReadiness: string;
    anchored: string;
    anchoredAway: string;
    allFast: string;
    loadingCommenced?: string;
    dischargingCommenced?: string;
    loadingCompleted?: string;
    dischargingCompleted?: string;
    armsDisconnected?: string;
    docsOnBoard?: string;
    vesselFigure?: string;
    terminalFigure?: string;
    allowedTime?: string;
    excessTime?: string;
}
// interface DemurrageInfoFormValues {
//     vesselName: string; // registered name of the vessel
//     charterPartyDate: string;
//     laycan: {
//         start: string;
//         end: string;
//     };
//     laytime: string; // hours
//     demurrageRate: string; // USD amount per day
//     cargo: string;
//     stops: {
//         portName: string;
//         agentSide: DemurrageInfoCharterPartyFormValues;
//         inspectorSide: DemurrageInfoCharterPartyFormValues;
//         shipSide: DemurrageInfoCharterPartyFormValues;
//     }[];
// }

// const defaultCharterPartyFormValues = {
//     noticeOfReadiness: "",
//     anchored: "",
//     anchoredAway: "",
//     allFast: "",
//     loadingCommenced: "",
//     dischargingCommenced: "",
//     loadingCompleted: "",
//     dischargingCompleted: "",
//     // armsDisconnected: "",
//     // docsOnBoard: "",
//     // vesselFigure: "",
//     // terminalFigure: "",
//     // allowedTime: "",
//     // excessTime: "",
// };
// const defaultFormValues = {
//     vesselName: "",
//     charterPartyDate: "",
//     laycan: {
//         start: "",
//         end: "",
//     },
//     laytime: "",
//     demurrageRate: "",
//     cargo: "",
//     stops: [
//         {
//             portName: "",
//             agentSide: clone(defaultCharterPartyFormValues),
//             inspectorSide: clone(defaultCharterPartyFormValues),
//             shipSide: clone(defaultCharterPartyFormValues),
//         },
//         {
//             portName: "",
//             agentSide: clone(defaultCharterPartyFormValues),
//             inspectorSide: clone(defaultCharterPartyFormValues),
//             shipSide: clone(defaultCharterPartyFormValues),
//         },
//     ],
// };

// const getDefaultValues = (
//     demurrageInfo: VoyageDemurrageInfo
// ): DemurrageInfoFormValues => {
//     return {
//         vesselName: demurrageInfo.vesselName?.extractedValue || "",
//         charterPartyDate: demurrageInfo.charterPartyDate?.extractedValue || "",
//         laycan: {
//             start: demurrageInfo.laycan.start?.extractedValue || "",
//             end: demurrageInfo.laycan.end?.extractedValue || "",
//         },
//         cargo: demurrageInfo.cargo?.extractedValue || "",
//         laytime: demurrageInfo.laytime?.extractedValue || "",
//         demurrageRate: demurrageInfo.demurrageRate?.extractedValue || "",
//         stops: demurrageInfo.stops?.map((stop) => {
//             return {
//                 portName: stop?.portName?.extractedValue || "",
//                 agentSide: {
//                     noticeOfReadiness:
//                         stop?.agentSide?.noticeOfReadiness?.extractedValue ||
//                         "",
//                     anchored:
//                         stop?.agentSide?.noticeOfReadiness?.extractedValue ||
//                         "",
//                     anchoredAway:
//                         stop?.agentSide?.noticeOfReadiness?.extractedValue ||
//                         "",
//                     allFast:
//                         stop?.agentSide?.noticeOfReadiness?.extractedValue ||
//                         "",
//                     loadingCommenced:
//                         stop?.agentSide?.noticeOfReadiness?.extractedValue ||
//                         "",
//                     dischargingCommenced:
//                         stop?.agentSide?.noticeOfReadiness?.extractedValue ||
//                         "",
//                     loadingCompleted:
//                         stop?.agentSide?.noticeOfReadiness?.extractedValue ||
//                         "",
//                     dischargingCompleted:
//                         stop?.agentSide?.noticeOfReadiness?.extractedValue ||
//                         "",
//                 },
//                 inspectorSide: {
//                     noticeOfReadiness:
//                         stop?.inspectorSide?.noticeOfReadiness
//                             ?.extractedValue || "",
//                     anchored:
//                         stop?.inspectorSide?.noticeOfReadiness
//                             ?.extractedValue || "",
//                     anchoredAway:
//                         stop?.inspectorSide?.noticeOfReadiness
//                             ?.extractedValue || "",
//                     allFast:
//                         stop?.inspectorSide?.noticeOfReadiness
//                             ?.extractedValue || "",
//                     loadingCommenced:
//                         stop?.inspectorSide?.noticeOfReadiness
//                             ?.extractedValue || "",
//                     dischargingCommenced:
//                         stop?.inspectorSide?.noticeOfReadiness
//                             ?.extractedValue || "",
//                     loadingCompleted:
//                         stop?.inspectorSide?.noticeOfReadiness
//                             ?.extractedValue || "",
//                     dischargingCompleted:
//                         stop?.inspectorSide?.noticeOfReadiness
//                             ?.extractedValue || "",
//                 },
//                 shipSide: {
//                     noticeOfReadiness:
//                         stop?.shipSide?.noticeOfReadiness?.extractedValue || "",
//                     anchored:
//                         stop?.shipSide?.noticeOfReadiness?.extractedValue || "",
//                     anchoredAway:
//                         stop?.shipSide?.noticeOfReadiness?.extractedValue || "",
//                     allFast:
//                         stop?.shipSide?.noticeOfReadiness?.extractedValue || "",
//                     loadingCommenced:
//                         stop?.shipSide?.noticeOfReadiness?.extractedValue || "",
//                     dischargingCommenced:
//                         stop?.shipSide?.noticeOfReadiness?.extractedValue || "",
//                     loadingCompleted:
//                         stop?.shipSide?.noticeOfReadiness?.extractedValue || "",
//                     dischargingCompleted:
//                         stop?.shipSide?.noticeOfReadiness?.extractedValue || "",
//                 },
//             };
//         }),
//     };
// };

const sides: ("agentSide" | "inspectorSide" | "shipSide")[] = [
    "agentSide",
    "inspectorSide",
    "shipSide",
];

interface VoyageDemurrageFormProps {
    scannings: Scanning[];
    demurrageInfo: VoyageDemurrageInfo;
}
const VoyageDemurrageForm: React.FC<VoyageDemurrageFormProps> = ({
    scannings,
    demurrageInfo,
}) => {
    const { id } = useParams()
    const updateVoyageInfoM = useMutation({
        mutationFn: (v: VoyageDemurrageInfo) => updateVoyageDemurrageInfo(1, v),
        onSuccess: (data) => {
            console.log("onSuccess", data);
        },
    });
    const isScanning = scannings?.some(scanning => scanning.status === "SCANNING")
    // const calculateDemurrageM = useMutation({
    //     mutationFn: (v: VoyageDemurrageInfo) => calculateVoyageDemurrage(1, v),
    //     onSuccess: (data) => {
    //         console.log("onSuccess", data);
    //         if (data.file_url) {
    //             const link = document.createElement("a");
    //             link.href = data?.file_url;
    //             link.download = `Voyage 1 - Demurrage Rate - ${formatDateTime(
    //                 new Date()
    //             )}.xlsx`;
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }
    //     },
    // });
    // const defaultFormValues: VoyageDemurrageInfo = useMemo(
    //     // () => getDefaultValues(demurrageInfo),
    //     [demurrageInfo]
    // );

    const scanningsMap = useMemo(() => {
        return scannings.reduce((acc, scanning) => {
            acc[scanning.filename] = scanning;
            return acc;
        }, {} as Record<string, Scanning>);
    }, [scannings]);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<VoyageDemurrageInfo>({
        // values: defaultFormValues,
        values: demurrageInfo,
        disabled: isScanning || updateVoyageInfoM.isPending,
    });

    useEffect(() => {
        console.log("errors", errors);
    }, [errors]);

    const { fields } = useFieldArray({
        control,
        name: "stops",
    });

    const onSubmit = (data: VoyageDemurrageInfo) => {
        console.log("SUBMIT", data);
        updateVoyageInfoM.mutate(data);
    };

    const getDescription = (scanning: Scanning) => {
        if (!scanning) {
            return null;
        }

        return (
            <Link
                showAnchorIcon
                href={`/voyages/${id}/files/${scanning?.id}`}
                target="_blank"
                color="foreground"
                className="text-tiny font-light opacity-50"
            >
                {scanning?.filename}
            </Link>
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            {/* <Button
                type="button"
                // type="submit"
                color="secondary"
                size="lg"
                className="mb-4"
                isLoading={scanDocumentsM.isPending}
                // isDisabled={calculateFormM.isSuccess}
                onPress={() => {
                    scanDocumentsM.mutate();
                }}
            >
                Scan Documents to Get Form Values
            </Button> */}
            <Controller
                name="vesselName.value"
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        fullWidth
                        label="Vessel Name"
                        placeholder="Enter vessel name"
                        description={getDescription(
                            scanningsMap[demurrageInfo?.vesselName?.fileName]
                        )}
                    />
                )}
            />
            <Controller
                name="charterPartyDate.value"
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        fullWidth
                        label="Charter Party Date"
                        placeholder="Enter charter party date"
                        description={getDescription(
                            scanningsMap[
                            demurrageInfo?.charterPartyDate?.fileName
                            ]
                        )}
                    />
                )}
            />
            <div className="flex gap-4">
                <Controller
                    name="laycan.start.value"
                    control={control}
                    render={({ field }) => (
                        <Input
                            className="flex-1"
                            {...field}
                            fullWidth
                            label="Laycan Start"
                            placeholder="Enter laycan start date"
                            description={getDescription(
                                scanningsMap[
                                demurrageInfo?.laycan?.start?.fileName
                                ]
                            )}
                        />
                    )}
                />
                <Controller
                    name="laycan.end.value"
                    control={control}
                    render={({ field }) => (
                        <Input
                            className="flex-1"
                            {...field}
                            fullWidth
                            label="Laycan End"
                            placeholder="Enter laycan end date"
                            description={getDescription(
                                scanningsMap[
                                demurrageInfo?.laycan?.end?.fileName
                                ]
                            )}
                        />
                    )}
                />
            </div>
            <Controller
                name="laytime.value"
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        fullWidth
                        label="Laytime"
                        placeholder="Enter laytime"
                        description={getDescription(
                            scanningsMap[demurrageInfo?.laytime?.fileName]
                        )}
                    />
                )}
            />
            <Controller
                name="demurrageRate.value"
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        fullWidth
                        label="Demurrage Rate"
                        placeholder="Enter demurrage rate"
                        description={getDescription(
                            scanningsMap[demurrageInfo?.demurrageRate?.fileName]
                        )}
                    />
                )}
            />
            <Controller
                name="cargo.value"
                control={control}
                render={({ field }) => (
                    <Input
                        {...field}
                        fullWidth
                        label="Cargo"
                        placeholder="Enter cargo"
                        description={getDescription(
                            scanningsMap[demurrageInfo?.cargo?.fileName]
                        )}
                    />
                )}
            />

            {fields.map((stop, index) => (
                <React.Fragment key={index}>
                    <h3 className="text-sm uppercase opacity-60 mt-6">
                        {stop?.portName?.value || `Port ${index + 1}`}
                    </h3>
                    <Controller
                        //  @ts-expect-error name format 
                        name={`stops.${index}.portName.value`}
                        control={control}
                        render={({ field }) => (
                            //  @ts-expect-error name format 
                            <Input
                                {...field}
                                fullWidth
                                label={`Port Name`}
                                placeholder="Enter port name"
                                description={getDescription(
                                    scanningsMap[
                                    demurrageInfo?.stops?.[index]?.portName
                                        ?.fileName
                                    ]
                                )}
                            />
                        )}
                    />
                    <Tabs>
                        {sides.map((side) => {
                            let sideLabel = "";
                            if (side === "agentSide") sideLabel = "Agent";
                            if (side === "inspectorSide")
                                sideLabel = "Inspector";
                            if (side === "shipSide") sideLabel = "Ship";

                            return (
                                <Tab key={side} title={sideLabel}>
                                    <React.Fragment>
                                        <Controller
                                            //  @ts-expect-error name format 
                                            name={`stops.${index}.${side}.noticeOfReadiness.value`}
                                            control={control}
                                            render={({ field }) => (
                                                //  @ts-expect-error name format 
                                                <Input
                                                    {...field}
                                                    fullWidth
                                                    label={`${sideLabel} / Notice of Readiness`}
                                                    placeholder="Enter notice of readiness"
                                                    description={getDescription(
                                                        scanningsMap[
                                                        demurrageInfo
                                                            ?.stops?.[
                                                            index
                                                        ]?.[side]
                                                            ?.noticeOfReadiness
                                                            ?.fileName
                                                        ]
                                                    )}
                                                />
                                            )}
                                        />
                                        <Controller
                                            //  @ts-expect-error name format 
                                            name={`stops.${index}.${side}.anchored.value`}
                                            control={control}
                                            render={({ field }) => (
                                                //  @ts-expect-error name format 
                                                <Input
                                                    {...field}
                                                    fullWidth
                                                    label={`${sideLabel} / Anchored`}
                                                    placeholder="Enter anchored"
                                                    description={getDescription(
                                                        scanningsMap[
                                                        demurrageInfo
                                                            ?.stops?.[
                                                            index
                                                        ]?.[side]?.anchored
                                                            ?.fileName
                                                        ]
                                                    )}
                                                />
                                            )}
                                        />
                                        <Controller
                                            //  @ts-expect-error name format 
                                            name={`stops.${index}.${side}.anchoredAway.value`}
                                            control={control}
                                            render={({ field }) => (
                                                //  @ts-expect-error name format 
                                                <Input
                                                    {...field}
                                                    fullWidth
                                                    label={`${sideLabel} / Anchored Away`}
                                                    placeholder="Enter anchored away"
                                                    description={getDescription(
                                                        scanningsMap[
                                                        demurrageInfo
                                                            ?.stops?.[
                                                            index
                                                        ]?.[side]
                                                            ?.anchoredAway
                                                            ?.fileName
                                                        ]
                                                    )}
                                                />
                                            )}
                                        />
                                        <Controller
                                            //  @ts-expect-error name format 
                                            name={`stops.${index}.${side}.allFast.value`}
                                            control={control}
                                            render={({ field }) => (
                                                //  @ts-expect-error name format 
                                                <Input
                                                    {...field}
                                                    fullWidth
                                                    label={`${sideLabel} / All Fast`}
                                                    placeholder="Enter all fest"
                                                    description={getDescription(
                                                        scanningsMap[
                                                        demurrageInfo
                                                            ?.stops?.[
                                                            index
                                                        ]?.[side]?.allFast
                                                            ?.fileName
                                                        ]
                                                    )}
                                                />
                                            )}
                                        />
                                        <Controller
                                            //  @ts-expect-error name format 
                                            name={`stops.${index}.${side}.loadingCommenced.value`}
                                            control={control}
                                            render={({ field }) => (
                                                //  @ts-expect-error name format 
                                                <Input
                                                    {...field}
                                                    fullWidth
                                                    label={`${sideLabel} / Loading / Discarging Commenced`}
                                                    placeholder="Enter loading / discharging commenced"
                                                    description={getDescription(
                                                        scanningsMap[
                                                        demurrageInfo
                                                            ?.stops?.[
                                                            index
                                                        ]?.[side]
                                                            ?.loadingCommenced
                                                            ?.fileName
                                                        ]
                                                    )}
                                                />
                                            )}
                                        />
                                        <Controller
                                            //  @ts-expect-error name format 
                                            name={`stops.${index}.${side}.loadingCompleted.value`}
                                            control={control}
                                            render={({ field }) => (
                                                //  @ts-expect-error name format 
                                                <Input
                                                    {...field}
                                                    fullWidth
                                                    label={`${sideLabel} / Loading / Discarging Completed`}
                                                    placeholder="Enter loading / discharging completed"
                                                    description={getDescription(
                                                        scanningsMap[
                                                        demurrageInfo
                                                            ?.stops?.[
                                                            index
                                                        ]?.[side]
                                                            ?.loadingCompleted
                                                            ?.fileName
                                                        ]
                                                    )}
                                                />
                                            )}
                                        />
                                    </React.Fragment>
                                </Tab>
                            );
                        })}
                    </Tabs>
                </React.Fragment>
            ))}
            <Button
                type="submit"
                color="secondary"
                size="lg"
                className="mt-4 h-14"
                isLoading={updateVoyageInfoM.isPending}
            // isDisabled={calculateDemurrageM.isSuccess}
            >
                Update Data
            </Button>
        </form>
    );
};

export default VoyageDemurrageForm;
