import axios from "axios";
import mockProjects from "src/pages/ProjectMap/mockProjects";
import { Scanning, VoyageDemurrageInfo } from "src/types";

const API_URL = "/api";
const ALI = true;

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

function setCookie(name: string, value: string, days: number) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie =
        name +
        "=" +
        encodeURIComponent(value) +
        "; expires=" +
        expires +
        "; path=/";
}

function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
    return null;
}

function deleteCookie(name: string) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
}

axios.interceptors.request.use(
    (config) => {
        const token = getCookie("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const loginUser = async ({
    email,
    password,
}: {
    email: string;
    password: string;
}) => {
    await sleep(500);
    const response = await axios.post(`${API_URL}/login`, { email, password });

    setCookie("token", response.data.access_token, 30);
    return response.data;
};

export const getMe = async () => {
    const response = await axios.get(`${API_URL}/me`);
    return response.data;
};

export const scanPdf = async (
    file: File,
    fileType: string,
    voyageId: string
) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_type", fileType);
    formData.append("voyage_id", voyageId);

    const response = await axios.post(`${API_URL}/scan-pdf`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return response.data;
};

export const getRegulations = async () => {
    const response = await axios.get(`${API_URL}/regulations`);
    return response.data;
};

export const getProjects = async () => {
    // const response = await axios.get(`${API_URL}/regulations`);
    return mockProjects;
    // return response.data;
};

export const getScannings = async (
    voyageId?: string | number
): Promise<Scanning[]> => {
    let queryParams = "";
    if (voyageId) {
        queryParams = `?voyage_id=${voyageId}`;
    }
    if (ALI) {
        const response = await axios.get(
            `${API_URL}/scannings-ali${queryParams}`
        );
        return response.data;
    } else {
        const response = await axios.get(
            `${API_URL}/scannings${queryParams}`
        );
        return response.data;
    }
};

export const getScanning = async (id: string | number): Promise<Scanning> => {
    if (ALI) {
        const response = await axios.get(`${API_URL}/scannings-ali/${id}`);
        return response.data;
    } else {
        const response = await axios.get(`${API_URL}/scannings/${id}`);
        return response.data;
    }
};

export const getVoyageDemurrageInfo = async (
    id: string | number
): Promise<VoyageDemurrageInfo> => {
    if (ALI) {
        const response = await axios.get(
            `${API_URL}/voyages/${id}/demurrage-info-ali`
        );
        return response.data;
    } else {
        const response = await axios.get(
            `${API_URL}/voyages/${id}1/demurrage-info`
        );
        return response.data;
    }
};

export const scanDocuments = async (
    id: string | number
): Promise<VoyageDemurrageInfo> => {
    const response = await axios.post(
        `${API_URL}/voyages/${id}/scan-documents`
    );
    return response.data;
};

export const createShellvoy6 = async (
    id: string | number
): Promise<VoyageDemurrageInfo> => {
    const response = await axios.post(
        `${API_URL}/voyages/${id}/create-shellvoy6`
    );
    return response.data;
};

export const updateVoyageDemurrageInfo = async (
    id: string | number,
    values: VoyageDemurrageInfo
): Promise<VoyageDemurrageInfo> => {
    console.log("TODO:", id);
    const response = await axios.post(
        `${API_URL}/voyages/${id}/update-demurrage-info`,
        values
    );
    return response.data;
};

// export const calculateVoyageDemurrage = async (
//     id: string | number,
//     values: VoyageDemurrageInfo
// ): Promise<VoyageDemurrageCalculationResult> => {
//     console.log("TODO:", id);
//     const response = await axios.post(
//         `${API_URL}/voyages/${id}/calculate-demurrage`,
//         values
//     );
//     return response.data;
// };

export const updateScanningBlock = async (
    scanningId: number | string,
    {
        field,
        value,
    }: {
        field: string;
        value: string;
    }
) => {
    const response = await axios.post(
        `${API_URL}/scannings/${scanningId}/update-block`,
        { field, value }
    );
    return response.data;
};

export const logoutUser = async () => {
    // await axios.post(`${API_URL}/logout`); // Optional: Call server-side logout endpoint
    deleteCookie("token");
};
