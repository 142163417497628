import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import { IProject } from "src/types";
import ProjectCountry from "./ProjectCountry";
import ProjectStatus from "./ProjectStatus";
import ProjectType from "./ProjectType";
import SDGIcon from "./SDGIcon";

export default function ProjectContainer({
  projects,
  selectedKey,
  hoveredProject,
  onHover,
  viewType,
  setSelectedProject,
  projectsExpanded,
}: {
  projects: IProject[];
  selectedKey?: number;
  hoveredProject?: IProject | null;
  onHover?: (project: IProject | null) => void;
  viewType?: "list" | "grid";
  setSelectedProject: (project: IProject | null) => void;
  projectsExpanded?: boolean;
}) {
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >({
    column: "sustaincert_id",
    direction: "ascending",
  });

  useEffect(() => {
    if (hoveredProject) {
      const row = document.getElementById(`project-row-${hoveredProject.id}`);
      const parentContainer =
        row?.closest("table")?.parentElement || // for table view
        row?.closest(".grid")?.parentElement; // for grid view

      if (!row) {
        return console.error("Row not found for regulation", hoveredProject);
      }

      if (!parentContainer) {
        return console.error(
          "Parent container not found for regulation",
          hoveredProject
        );
      }

      const rect = row.getBoundingClientRect();
      const parentRect = parentContainer.getBoundingClientRect();

      const isInViewport =
        rect.top <= parentRect.top && rect.bottom >= parentRect.bottom;

      if (!isInViewport) {
        parentContainer.scrollTo({
          top: row.offsetTop - 70,
          behavior: "smooth",
        });
      }
    }
  }, [hoveredProject]);

  const sortedProjects: IProject[] = useMemo(() => {
    const newSortedRegulations = [...projects].sort((a, b) => {
      switch (sortDescriptor?.column) {
        case "sustaincert_id":
          return a.sustaincert_id - b.sustaincert_id;
        case "name":
          return a.name.localeCompare(b.name);
        case "status":
          return a.status.localeCompare(b.status);
        case "type":
          return a.type.localeCompare(b.type);
        default:
          console.error("Unknown column", sortDescriptor?.column);
          return -1;
      }
    });
    if (sortDescriptor?.direction === "descending") {
      newSortedRegulations.reverse();
    }
    return newSortedRegulations;
  }, [projects, sortDescriptor]);

  const handleMouseEnter = (regulation: IProject) => {
    onHover?.(regulation);
  };

  const handleMouseLeave = () => {
    onHover?.(null);
  };

  const handleSortChange = (descriptor: SortDescriptor) => {
    setSortDescriptor(descriptor);
  };

  if (viewType === "list") {
    return (
      <Table
        isHeaderSticky
        selectionMode="single"
        aria-label="Example static collection table"
        className={`transition-size ${
          projectsExpanded ? "h-[70vh]" : "h-[35vh]"
        }`}
        selectedKeys={selectedKey ? [selectedKey] : []}
        sortDescriptor={sortDescriptor}
        onSortChange={handleSortChange}
      >
        <TableHeader>
          <TableColumn> </TableColumn>
          <TableColumn> </TableColumn>
          <TableColumn allowsSorting key="sustaincert_id">
            PROJECT ID
          </TableColumn>
          <TableColumn allowsSorting key="status">
            STATUS
          </TableColumn>
          <TableColumn allowsSorting key="name" className="min-w-52">
            NAME
          </TableColumn>
          <TableColumn allowsSorting key="type">
            PROJECT TYPE
          </TableColumn>
          <TableColumn>SDGs</TableColumn>
        </TableHeader>
        <TableBody>
          {sortedProjects.map((project) => {
            const isHovered = project.id === hoveredProject?.id;
            const borderClass = isHovered ? "bg-secondary/15" : "";
            return (
              <TableRow
                id={`project-row-${project.id}`}
                key={project.id}
                onMouseEnter={() => handleMouseEnter(project)}
                onMouseLeave={handleMouseLeave}
                className={`cursor-pointer active:bg-secondary/15 transition-colors ${borderClass}`}
                onClick={() => {
                  console.log("Row clicked", project);
                  setSelectedProject(project);
                }}
              >
                <TableCell>
                  <Tooltip content={project.type}>
                    <span>
                      <ProjectType.Icon project={project} />
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip content={project.country}>
                    <span>
                      <ProjectCountry project={project} />
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>GS{project.sustaincert_id}</TableCell>
                <TableCell>
                  <ProjectStatus project={project} />
                </TableCell>
                <TableCell>
                  <span className="text-nowrap">{project.name}</span>
                  <br />
                  <span className="text-sm opacity-50 text-nowrap">
                    by {project.project_developer}
                  </span>
                </TableCell>
                <TableCell>
                  <ProjectType project={project} />
                </TableCell>
                <TableCell className="flex">
                  {project.sustainable_development_goals.map((sdg) => {
                    return <SDGIcon key={sdg.name} sdg={sdg} />;
                  })}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  return (
    <div
      className={`overflow-scroll transition-size ${
        projectsExpanded ? "h-[70vh]" : "h-[35vh]"
      }`}
    >
      <div className="grid grid-cols-12 gap-6 p-6">
        {projects.map((project) => {
          const isHovered = project.id === hoveredProject?.id;
          const borderClass = isHovered ? "border-2 border-secondary-500" : "";
          const randomImg = Number(project.id) % 5;

          return (
            <Card
              key={project.id}
              id={`project-row-${project.id}`}
              isFooterBlurred
              className={`min-h-[400px] col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 p-0 ${borderClass}`}
              onMouseEnter={() => handleMouseEnter(project)}
              onMouseLeave={handleMouseLeave}
              isPressable
              onPress={() => {
                console.log("card clicked", project);
                setSelectedProject(project);
              }}
            >
              <CardHeader className="absolute z-10 top-0 h-12 flex justify-between items-start gap-3 overflow-hidden">
                <p className="text-left text-nowrap opacity/60 uppercase font-bold">
                  {project.country}
                </p>

                <div className="flex-1" />
                <Tooltip content={project.type}>
                  <span>
                    <ProjectType.Icon project={project} />
                  </span>
                </Tooltip>
                <ProjectCountry project={project} />
              </CardHeader>
              <CardBody className="absolute z-10 top-12 bottom-16 flex flex-col gap-2 overflow-hidden">
                <p>{project.name}</p>
                <p className="text-tiny opacity-50">
                  by {project.project_developer}
                </p>
                <span className="flex-1"></span>
                <p className="text-tiny text-slate-600 dark:text-slate-400 uppercase">
                  Project Type
                </p>
                <p className="text-sm">{project.type}</p>
                <p className="text-tiny text-slate-600 dark:text-slate-400">
                  SDGs
                </p>
                <div className="flex">
                  {project.sustainable_development_goals.map((sdg) => {
                    return <SDGIcon key={sdg.name} sdg={sdg} />;
                  })}
                </div>
              </CardBody>
              <div className="z-0 w-full h-full relative">
                <Image
                  removeWrapper
                  alt="Relaxing app background"
                  className="z-0 w-full h-full object-cover"
                  src={`/mock-card/${randomImg}.png`}
                />
                <div className="absolute inset-0 bg-white dark:bg-black opacity-85 dark:opacity-75"></div>
              </div>
              <CardFooter className="flex flex-col justify-between items-start absolute h-16 bg-white/30 dark:bg-black/30 bottom-0 z-10">
                <div className="w-full h-full flex justify-between items-center">
                  <ProjectStatus project={project} />
                  <Button
                    size="sm"
                    as="span"
                    radius="full"
                    variant="light"
                    className="opacity-60"
                    onClick={() => {
                      console.log("Row clicked", project);
                      setSelectedProject(project);
                    }}
                  >
                    Details
                  </Button>
                </div>
              </CardFooter>
            </Card>
          );
        })}
      </div>
    </div>
  );
}
