import { Chip, ChipProps } from "@nextui-org/react";
import StatusDesignCertifiedLogo from "src/assets/project-status-design-certified.svg?react";
import StatusListedLogo from "src/assets/project-status-listed.svg?react";
import StatusProjectCertifiedLogo from "src/assets/project-status-project-certified.svg?react";
import { IProject } from "src/types";

export default function ProjectStatus({
  project,
  status,
}: {
  project?: IProject;
  status?: string;
}) {
  const statusText = project?.status || status || "N/A";
  const color: ChipProps["color"] =
    statusText === "LISTED" ? "success" : "warning";

  let startContent = <StatusListedLogo className={`w-7 h-7 stroke-success`} />;
  if (statusText === "DESIGN CERTIFIED") {
    startContent = (
      <StatusDesignCertifiedLogo className={`w-7 h-7 stroke-warning`} />
    );
  } else if (statusText === "PROJECT CERTIFIED") {
    startContent = (
      <StatusProjectCertifiedLogo className={`w-7 h-7 stroke-warning`} />
    );
  }

  return (
    <Chip
      className="capitalize h-10 px-2"
      color={color}
      variant="flat"
      startContent={startContent}
    >
      {statusText}
    </Chip>
  );
}
